/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Alert } from "qdm-component-library";

export const Alerts = (props) => {
  const [open, setOpen] = React.useState(props.open);

  const handleClose = (event) => {
    props.onclose();
    setOpen(false);
  };

  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        position: "fixed",
        top: 10,
        width: "100%",
        zIndex: 1000000,
      }}
    >
      <Alert
        id="main_alert"
        name="main_alert"
        onClose={handleClose}
        variant="filled"
        // inLineStyles={{
        //   zIndex: 1000000,
        //   top: 10,
        //   position: "fixed",
        // }}
        {...props}
      >
        {props.msg}
      </Alert>
    </div>
  );
};
