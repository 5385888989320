import React from "react";
import { actions } from "realwarebinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import "./index.css";
import { LoggerContext } from "../../contexts";
import { Logout } from "../../components";
import { AinqaFlex, Dropdown } from "qdm-component-library";
import { DatePicker, Spin, Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import Axios from "axios";
import Config from "../../config";

class Reports extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      reports_2opew: null,
      filter: false,
      loading: false,
      defaultReportsQuery: {
        db_name: "ATP_Platform_DEV",
        entity: "QDMReports",
        is_metadata: false,
        filter: "QDMReports._key=='213039128'",
        doc: {
          reportid: "5de79b37-f75b-4baf-b948-c061bd95a4fd",
          reportname: "newglobal",
          reporttype: "flexmonster",
          readquery:
            '{\n "db_name": "Real_Wear",\n "entity": "Encounter,Patient",\n "filter": {\n "Encounter": "Encounter.activestatus==true",\n "Patient": "Patient._id==Encounter.Patient_ID && Patient.activestatus==true"\n },\n "return_fields": "{EncounterNo:Encounter.Encounter_Number,PatientName:Patient.Patient_Name,MRN:Patient.MRN,SpecialistDoctor:(for Practitioner in Practitioner filter Practitioner.Practitioner_ID==Encounter.Practitioner_ID && Practitioner.activestatus==true return Practitioner.Practitioner_Name)[0],DateTime:(for AVCLogs in AVCLogs filter AVCLogs.Meeting_ID==Encounter.Meeting_ID && AVCLogs.activestatus==true sort AVCLogs.createddate asc limit 1 return AVCLogs.DateTime)[0],Duration:(let a=(for AVCLogs in AVCLogs filter AVCLogs.Meeting_ID==Encounter.Meeting_ID && AVCLogs.activestatus==true sort AVCLogs.createddate asc limit 1 return DATE_TIMESTAMP(AVCLogs.createddate*1000)) let b=(for AVCLogs in AVCLogs filter AVCLogs.Meeting_ID==Encounter.Meeting_ID && AVCLogs.activestatus==true sort AVCLogs.createddate desc limit 1 return DATE_TIMESTAMP(AVCLogs.createddate*1000)) return DATE_DIFF(a[0],b[0],\'i\'))[0],facilityname:document(Patient.Facility_ID).Facility_Name,record:Encounter.record}"\n}',
          readjsonschema:
            '{\n "type": "object",\n "properties": {\n "db_name": {\n "type": "string"\n },\n "entity": {\n "type": "string"\n },\n "filter": {\n "type": "object",\n "properties": {\n "Encounter": {\n "type": "string"\n },\n "Patient": {\n "type": "string"\n }\n }\n },\n "return_fields": {\n "type": "string",\n "format": "style"\n }\n }\n}',
          template: {
            slice: {
              rows: [
                {
                  uniqueName: "Encounter No",
                },
                {
                  uniqueName: "Patient Name",
                },
                {
                  uniqueName: "MRN",
                },
                {
                  uniqueName: "Specialist Doctor",
                },
                {
                  uniqueName: "Date And Time",
                },
                {
                  uniqueName: "Duration",
                },
                {
                  uniqueName: "facility Name",
                },
                {
                  uniqueName: "record",
                },
              ],
              columns: [
                {
                  uniqueName: "[Measures]",
                },
              ],
              flatOrder: [
                "Encounter No",
                "Patient Name",
                "MRN",
                "Specialist Doctor",
                "Date And Time",
                "Duration",
                "facility Name",
                "record",
              ],
            },
            options: {
              grid: {
                type: "flat",
                showTotals: "off",
                showGrandTotals: "off",
              },
            },
            version: "2.9.16",
            creationDate: "2022-02-07T11:22:59.710Z",
            dataSource: {
              data: [],
              type: "json",
            },
          },
          fmrender: [],
          fmheadfoot: {},
          clientid: "clients/187314910",
          metadataid: "e38e2f6a-57fd-41c2-b065-18b614db9830",
          projectid: "projects/187315907",
        },
      },
      defaultQuery: {
        db_name: "Real_Wear",
        entity: "Encounter,Patient",
        filter: {
          Encounter: "Encounter.activestatus==true",
          Patient:
            "Patient._id==Encounter.Patient_ID && Patient.activestatus==true",
        },
        return_fields:
          "{EncounterNo:Encounter.Encounter_Number,PatientName:Patient.Patient_Name,MRN:Patient.MRN,SpecialistDoctor:(for Practitioner in Practitioner filter Practitioner.Practitioner_ID==Encounter.Practitioner_ID && Practitioner.activestatus==true return Practitioner.Practitioner_Name)[0],DateTime:(for AVCLogs in AVCLogs filter AVCLogs.Meeting_ID==Encounter.Meeting_ID && AVCLogs.activestatus==true sort AVCLogs.createddate asc limit 1 return AVCLogs.DateTime)[0],Duration:(let a=(for AVCLogs in AVCLogs filter AVCLogs.Meeting_ID==Encounter.Meeting_ID && AVCLogs.activestatus==true sort AVCLogs.createddate asc limit 1 return DATE_TIMESTAMP(AVCLogs.createddate*1000)) let b=(for AVCLogs in AVCLogs filter AVCLogs.Meeting_ID==Encounter.Meeting_ID && AVCLogs.activestatus==true sort AVCLogs.createddate desc limit 1 return DATE_TIMESTAMP(AVCLogs.createddate*1000)) return DATE_DIFF(a[0],b[0],'i'))[0],facilityname:document(Patient.Facility_ID).Facility_Name,record:Encounter.record}",
      },
      searchFields: {
        specialist: "",
        facility: "",
        startDate: "",
        endDate: "",
      },
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleSearch = async () => {
    this.setState({
      loading: true,
    });
    // build filters based on the input

    let query = this.state.defaultQuery;
    let searchFields = this.state.searchFields;

    query.filter["Encounter"] = `${query.filter["Encounter"]} ${
      searchFields?.specialist.length > 0
        ? `&& Encounter.Practitioner_ID=='${searchFields?.specialist}'`
        : ""
    }`;
    query.filter["Encounter"] = `${query.filter["Encounter"]} ${
      searchFields.startDate.toString().length > 0
        ? `&& Encounter.createddate>=${searchFields?.startDate} && Encounter.createddate<=${searchFields?.endDate}`
        : ""
    }`;
    query.filter["Patient"] = `${query.filter.Patient} ${
      searchFields?.facility.length > 0
        ? `&& Patient.Facility_ID=='${searchFields?.facility}'`
        : ""
    }`;

    await this.props.ReportsEncounter_Full_Read_2opEW({ filter: query.filter });

    //replace the filter to the initial query in the state
    let reportsQuery = this.state.defaultReportsQuery;

    reportsQuery = {
      ...reportsQuery,
      doc: {
        ...reportsQuery.doc,
        readquery: JSON.stringify(query),
      },
    };

    //do the network call
    var data = JSON.stringify([reportsQuery]);
    var config = {
      method: "post",
      url: "https://arangodbservice.dev.ainqaplatform.in/api/updatedocument",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    var self = this;
    Axios(config)
      .then(function (response) {
        console.log(response);
        setTimeout(() => {
          self.setState({
            loading: false,
            filter: true,
          });
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleClear = async () => {
    this.setState({
      loading: true,
    });
    var data = JSON.stringify([this.state.defaultReportsQuery]);
    var config = {
      method: "post",
      url: "https://arangodbservice.dev.ainqaplatform.in/api/updatedocument",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    var self = this;
    Axios(config)
      .then(function (response) {
        console.log(response);
        setTimeout(() => {
          self.setState({
            loading: false,
            filter: false,
          });
          window.location.reload();
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async componentDidMount() {
    /// sample localstorage translation
    await this.handleLoad();

    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }

    await this.props.SpecialityDoctors({
      keyName: "Practitioner_Name",
      valueName: "Practitioner_ID",
    });
    await this.props.FacilityName({
      keyName: "Facility_Name",
      valueName: "_id",
    });

    const payload = { history: this.props.history.location.state };
    await this.props.ReportsEncounter_Full_Read_2opEW(payload);
  }
  handleLoad = () => {
    this.setState({
      loading: true,
    });
    var data = JSON.stringify([this.state.defaultReportsQuery]);
    var config = {
      method: "post",
      url: "https://arangodbservice.dev.ainqaplatform.in/api/updatedocument",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    var self = this;
    Axios(config)
      .then(function (response) {
        console.log(response);
        setTimeout(() => {
          self.setState({
            loading: false,
            filter: false,
          });
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  shouldComponentUpdate(props, state) {
    if (
      this.props?.Encounter_Full_ReadData?.loading !==
      props?.Encounter_Full_ReadData?.loading
    ) {
      if (
        !props?.Encounter_Full_ReadData?.loading &&
        props?.Encounter_Full_ReadData.error
      ) {
      } else if (
        !props?.Encounter_Full_ReadData?.loading &&
        !props?.Encounter_Full_ReadData.error
      ) {
      }
    }

    return true;
  }

  render() {
    const { RangePicker } = DatePicker;
    const {
      EncounterData,
      Encounter_Full_ReadData,
      data,
      specialityDoctorsOptions,
      facilityNameOptions,
    } = this.props;

    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <div key={"0"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"reports"}
              ></input>
            </div>
            <div key={"1"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"reports"}
              ></input>
              <div key={"1"} class={"reports screen"}>
                <div key={"0"} class={"group-93718-3zrh10"}>
                  {/* filter fields */}
                  <p style={{ marginLeft: "12px" }}>Quick Filters</p>
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      margin: "5px auto 24px",
                      gridTemplateColumns: "25% 25% 25% 25%",
                      gridGap: "24px",
                      alignItems: "end",
                    }}
                  >
                    <div>
                      <Dropdown
                        key={"0"}
                        elevation={"1"}
                        fullWidth={true}
                        dropDownTextSize={"14"}
                        dropDownTextColor={"#000"}
                        activeTextColor={"#000"}
                        dropdownListMargin={"0"}
                        onChange={(val) => {
                          this.setState({
                            searchFields: {
                              ...this.state.searchFields,
                              specialist: val.Practitioner_ID,
                            },
                          });
                        }}
                        dropdownListPadding={"8"}
                        options={specialityDoctorsOptions}
                        label={t("Specialist")}
                        labelStyle={{
                          fontSize: "12px",
                          marginBottom: "6px",
                          color: "gray",
                          marginTop: "0px",
                        }}
                        Url={""}
                        keyName={""}
                        valueName={""}
                        placeholder={""}
                        inLineStyles={{
                          padding: "5px 4px",
                          fontSize: "14px",
                          border: "1px solid #d9d9d9",
                        }}
                      ></Dropdown>
                    </div>
                    <div>
                      <Dropdown
                        key={"0"}
                        elevation={"1"}
                        fullWidth={true}
                        dropDownTextSize={"14"}
                        dropDownTextColor={"#000"}
                        activeTextColor={"#000"}
                        dropdownListMargin={"0"}
                        onChange={(val) => {
                          this.setState({
                            searchFields: {
                              ...this.state.searchFields,
                              facility: val.value,
                            },
                          });
                        }}
                        dropdownListPadding={"8"}
                        options={facilityNameOptions}
                        label={t("Facility")}
                        labelStyle={{
                          fontSize: "12px",
                          marginBottom: "6px",
                          color: "gray",
                          marginTop: "0px",
                        }}
                        Url={""}
                        keyName={""}
                        valueName={""}
                        placeholder={""}
                        inLineStyles={{
                          padding: "5px 4px",
                          fontSize: "14px",
                          border: "1px solid #d9d9d9",
                        }}
                      ></Dropdown>
                    </div>
                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          marginBottom: "6px",
                          color: "gray",
                          marginTop: "0px",
                        }}
                      >
                        Date Range
                      </p>
                      <RangePicker
                        format="DD/MM/YYYY"
                        onChange={(val) => {
                          if (val && val.length === 2) {
                            this.setState({
                              searchFields: {
                                ...this.state.searchFields,
                                startDate: moment(val[0]).startOf("day").unix(),
                                endDate: moment(val[1]).endOf("day").unix(),
                              },
                            });
                          } else {
                            this.setState({
                              searchFields: {
                                ...this.state.searchFields,
                                startDate: "",
                                endDate: "",
                              },
                            });
                          }
                        }}
                        disabledDate={(d) => !d || d.isAfter(new Date())}
                      />
                    </div>
                    <div>
                      {this.state.filter ? (
                        <Button
                          icon={
                            <CloseCircleOutlined style={{ marginTop: "4px" }} />
                          }
                          onClick={this.handleClear}
                        >
                          Clear Search
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          disabled={
                            Object.values(this.state.searchFields).some(
                              (val) => val !== ""
                            )
                              ? false
                              : true
                          }
                          onClick={this.handleSearch}
                        >
                          Search
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div key={"1"} class={"group-93718-3zrh11"}>
                  <div>
                    {!this.state.loading && (
                      <AinqaFlex
                        reportid="5de79b37-f75b-4baf-b948-c061bd95a4fd"
                        apiurl="https://arangodbservice.dev.ainqaplatform.in/api"
                        inputparams={""}
                        dbName={"ATP_Platform_DEV"}
                        data={Encounter_Full_ReadData.result}
                        env={Config.file_upload}
                      />
                    )}
                    {this.state.loading && (
                      <div
                        style={{
                          display: "grid",
                          marginTop: "64px",
                          placeItems: "center",
                        }}
                      >
                        <Spin />
                      </div>
                    )}
                  </div>
                </div>
                <div key={"1"} class={"group-93712-3zrh10"}>
                  <div key={"0"} class={"rectangle-47180-IEZVGu"}></div>
                  <div key={"1"} class={"login-avatar-IEZVGu"}>
                    <Logout />
                  </div>
                  <div key={"2"} class={"reports-IEZVGu"}>
                    Reports
                  </div>
                </div>
                <div key={"2"} class={"group-93711-3zrh10"}>
                  <div key={"0"} class={"rectangle-47173-glUWHL"}></div>
                  <div key={"1"} class={"group-93715-glUWHL"}>
                    <div key={"0"} class={"logo-38rwxk"}>
                      <img
                        key={"0"}
                        class={"x51-client_profile_subscription-10-Nixsk1"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183801383"
                        }
                        alt={"5-1-client-profile-subscription---10@1x.png"}
                      ></img>
                      <div key={"1"} class={"global-doctors-Nixsk1"}>
                        <span key={"0"} class={"span0-c7eoPE"}>
                          Global
                        </span>
                        <span key={"1"} class={"span1-c7eoPE"}>
                          Doctors
                        </span>
                      </div>
                    </div>
                    <div key={"1"} class={"rectangle-47179-38rwxk"}></div>
                    <div key={"2"} class={"login-details-38rwxk"}>
                      <div key={"0"} class={"hello-axe3dy"}>
                        Hello{" "}
                      </div>
                      <div key={"1"} class={"mr-rakin-axe3dy"}>
                        {sessionStorage.getItem("name")}
                      </div>
                      <div key={"2"} class={"sathishrakinainqacom-axe3dy"}>
                        {sessionStorage.getItem("username")}
                      </div>
                      <img
                        key={"3"}
                        class={"line-9-axe3dy"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183801422"
                        }
                        alt={"line-9@1x.png"}
                      ></img>
                    </div>
                    <div key={"3"} class={"reports-38rwxk"}>
                      <div
                        key={"0"}
                        class={"reports-XiX9S9"}
                        style={{ cursor: "pointer" }}
                      >
                        Reports
                      </div>
                      <img
                        key={"1"}
                        class={"download-7-XiX9S9"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183801397"
                        }
                        alt={"download--7-@1x.png"}
                      ></img>
                    </div>
                    <div key={"5"} class={"master-38rwxk"}>
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/specialist_doctor");
                        }}
                        class={"master-QjIt1f roboto-medium-dove-gray-14px"}
                        style={{ cursor: "pointer" }}
                      >
                        Master
                      </div>
                      <img
                        key={"1"}
                        class={"material-master-data-QjIt1f"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183801432"
                        }
                        alt={
                          "material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"
                        }
                      ></img>
                    </div>
                    <div key={"6"} class={"group-93714-38rwxk"}>
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/visit_registration");
                        }}
                        class={
                          "visit-registration-3JfVI6 roboto-medium-dove-gray-14px"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Visit Registration
                      </div>
                      <img
                        key={"1"}
                        class={"download-6-3JfVI6"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183801390"
                        }
                        alt={"download--6-@1x.png"}
                      ></img>
                    </div>
                  </div>
                </div>
                <div key={"3"} class={"version-3zrh10"}>
                  <div key={"0"} class={"v1001-3jxhjo"}>
                    {"v" + localStorage?.version ?? ""}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}

const mapStateToProps = (state) => ({
  Encounter_Full_ReadData:
    state?.ReportsEncounter_Full_Read_2opEWReducer?.Encounter_Full_Read_2opEW
      ?.data,
  facilityNameOptions: state.All_Dropdowns_Reducer?.facilityName?.data?.result,
  specialityDoctorsOptions:
    state.All_Dropdowns_Reducer?.specialityDoctors?.data?.result,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(Reports)));
