import React from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import { withAllContexts } from "../../HOCs";

function LogoutUI(props) {
  let history = useHistory();
  const handleLogout = () => {
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("token");
    // sessionStorage.removeItem("username");
    // sessionStorage.removeItem("remember_me");
    sessionStorage.removeItem("facility");
    props.alert.setSnack({
      open: true,
      msg: "Logged out successfully!",
      severity: "success",
      setTimeOut: 1000,
    });
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  return (
    <div className="logout_container" onClick={handleLogout}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="20"
        height="20"
        style={{
          margin: "0px 8px 0px 0px",

          pointerEvents: "none",
        }}
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z"
          fill="currentColor"
        />
      </svg>
      <p style={{ fontSize: "14px", cursor: "pointer", marginBottom: "0px" }}>
        Log Out
      </p>
    </div>
  );
}

export const Logout = withAllContexts(LogoutUI);
