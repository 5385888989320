import React from "react";
import { actions } from "realwarebinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import "./index.css";
import { languageOptions } from "../../utils";
import { LoggerContext } from "../../contexts";
import { Logout } from "../../components";

import * as ALL from "../../components";

import { div } from "qdm-component-library";

class OnsiteDoctor extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = { onsite_doctor_qtqfv: null, onsite_doctor_tjyam: null };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onclick_tjyam = async (event, data) => {
    const payload0 = { history: this.props.history.location.state, data: data };
    let data0 = await this.props.Onsite_DoctorPractitioner_SoftDelete_TJyam(
      payload0
    );

    ///refresh enabled
    if (data0?.payload?.Code === 200) {
      window.location.reload("");
    }
  };
  componentDidMount() {
    /// sample localstorage translation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }

    const payload = { history: this.props.history.location.state };
    this.props.Onsite_DoctorPractitioner_Onsight_Fullread_Updated_QtQFV(
      payload
    );
  }

  shouldComponentUpdate(props, state) {
    if (
      this.props?.Practitioner_SoftDeleteData?.loading !==
      props?.Practitioner_SoftDeleteData?.loading
    ) {
      if (
        !props?.Practitioner_SoftDeleteData?.loading &&
        props?.Practitioner_SoftDeleteData.error
      ) {
      } else if (
        !props?.Practitioner_SoftDeleteData?.loading &&
        !props?.Practitioner_SoftDeleteData.error
      ) {
      }
    }

    return true;
  }

  render() {
    const {
      PractitionerData,
      Practitioner_Onsight_Fullread_UpdatedData,
      Practitioner_SoftDeleteData,
      data,
    } = this.props;

    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <div key={"0"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"master-onsite-doctor"}
              ></input>
              <div key={"1"} class={"master-onsite-doctor screen"}>
                <div
                  key={"0"}
                  onClick={() => {
                    this.props.history.push("/specialist_doctor");
                  }}
                  class={"specialist-doctor-34YBJ2"}
                  style={{ cursor: "pointer" }}
                >
                  Specialist Doctor
                </div>
                <div
                  key={"1"}
                  onClick={() => {
                    this.props.history.push("/patient_facility");
                  }}
                  class={"patient-facility-34YBJ2"}
                  style={{ cursor: "pointer" }}
                >
                  Patient Facility
                </div>
                <div key={"2"} class={"qdm-button-34YBJ2"}>
                  <div
                    key={"0"}
                    onClick={() => {
                      this.props.history.push("/add_onsite_doctor");
                    }}
                    class={"add-new-TxYSuY"}
                    style={{ cursor: "pointer" }}
                  >
                    Add New
                  </div>
                </div>
                <div key={"3"} class={"onsite-doctor-34YBJ2"}>
                  <div
                    key={"0"}
                    class={"onsite-doctor-medical-assistant-DpGfc4"}
                  >
                    Onsite Doctor/Medical Assistant
                  </div>
                  <img
                    key={"1"}
                    class={"rectangle-47181-DpGfc4"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770632"
                    }
                    alt={"rectangle-47181@1x.png"}
                  ></img>
                </div>
                <div key={"4"} class={"group-93711-34YBJ2"}>
                  <div key={"0"} class={"rectangle-47173-SdQL2q"}></div>
                  <div key={"1"} class={"group-93715-SdQL2q"}>
                    <div key={"0"} class={"logo-4qQjuh"}>
                      <img
                        key={"0"}
                        class={"x51-client_profile_subscription-10-Xgp2pp"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739820"
                        }
                        alt={"5-1-client-profile-subscription---10@1x.png"}
                      ></img>
                      <div key={"1"} class={"global-doctors-Xgp2pp"}>
                        <span key={"0"} class={"span0-qAzcIm"}>
                          Global
                        </span>
                        <span key={"1"} class={"span1-qAzcIm"}>
                          Doctors
                        </span>
                      </div>
                    </div>
                    <div key={"1"} class={"login-details-4qQjuh"}>
                      <div key={"0"} class={"hello-bIS0dc"}>
                        Hello{" "}
                      </div>
                      <div key={"1"} class={"mr-rakin-bIS0dc"}>
                        {sessionStorage.getItem("name")}
                      </div>
                      <div key={"2"} class={"sathishrakinainqacom-bIS0dc"}>
                        {sessionStorage.getItem("username")}
                      </div>
                      <img
                        key={"3"}
                        class={"line-9-bIS0dc"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744502"
                        }
                        alt={"line-9@1x.png"}
                      ></img>
                    </div>
                    <div key={"2"} class={"reports-4qQjuh"}>
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/reports");
                        }}
                        class={"reports-wKwQMh roboto-medium-dove-gray-14px"}
                        style={{ cursor: "pointer" }}
                      >
                        Reports
                      </div>
                      <img
                        key={"1"}
                        class={"download-7-wKwQMh"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744506"
                        }
                        alt={"download--7-@1x.png"}
                      ></img>
                    </div>
                    <div key={"4"} class={"rectangle-47179-4qQjuh"}></div>
                    <div key={"5"} class={"master-4qQjuh"}>
                      <div
                        key={"0"}
                        class={"master-nerYZy"}
                        style={{ cursor: "pointer" }}
                      >
                        Master
                      </div>
                      <img
                        key={"1"}
                        class={"material-master-data-nerYZy"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744545"
                        }
                        alt={
                          "material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"
                        }
                      ></img>
                    </div>
                    <div key={"6"} class={"group-93714-4qQjuh"}>
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/visit_registration");
                        }}
                        class={
                          "visit-registration-JA0VlI roboto-medium-dove-gray-14px"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Visit Registration
                      </div>
                      <img
                        key={"1"}
                        class={"download-6-JA0VlI"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744562"
                        }
                        alt={"download--6-@1x.png"}
                      ></img>
                    </div>
                  </div>
                </div>
                <div key={"5"} class={"version-34YBJ2"}>
                  <div key={"0"} class={"v1001-nN4wSn"}>
                    {"v" + localStorage?.version ?? ""}
                  </div>
                </div>
                <div key={"6"} class={"group-93712-34YBJ2"}>
                  <div key={"0"} class={"rectangle-47180-TyQF2r"}></div>
                  <div key={"1"} class={"login-avatar-TyQF2r"}>
                    <Logout />
                  </div>
                  <div key={"2"} class={"master-TyQF2r"}>
                    Master
                  </div>
                </div>
                <div key={"7"} class={"group-93713-34YBJ2"}>
                  <div key={"0"} class={"rectangle-47148-o1ODmP"}></div>

                  <div
                    key={"3"}
                    class={"qdm-list-o1ODmP"}
                    style={qdmstyles.WtNwZ}
                  >
                    <table style={{ width: "100%" }}>
                    <tr style={{ borderBottom: '1px solid #e0e0e0' }}>
                      <th style={{ padding: "10px 10px 10px 20px" }}>Name  </th>
                      <th>Speciality </th>
                      <th>Location</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Mobile</th>
                      <th>Actions</th>
                    </tr>
                    {Array.isArray(
                      Practitioner_Onsight_Fullread_UpdatedData?.["result"]
                    ) &&
                      Practitioner_Onsight_Fullread_UpdatedData?.[
                        "result"
                      ]?.map((data, index) => {
                        return (
                        <tr>
                           <td style={{ padding: "10px 10px 10px 20px" }}> {data?.Practitioner_Name}</td>
                          <td> {data?.Specialty_ID?.Specialty_Name}</td>
                          <td>{data?.Location}</td>
                          <td>{data?.Practitioner_Mail_ID}</td>
                          <td style={{width:"220px"}}> {data?.Address}</td>
                          <td>{data?.Phone_Number}</td>
                          <td>
                          <div key={"0"} >
                               
                                <img
                                  key={"1"}
                                  style={{ width: "16px", marginRight: 10 }}
                                  onClick={() => {
                                    this.props.history.push({
                                      pathname: "/edit_onsite_doctor",
                                      state: {
                                        Onsite_Doctor__key:
                                          typeof index === "undefined"
                                            ? Array.isArray(
                                                this.props
                                                  .Practitioner_Onsight_Fullread_UpdatedData
                                                  ?.data
                                              )
                                              ? this.props
                                                  .Practitioner_Onsight_Fullread_UpdatedData
                                                  ?.data[0]._key
                                              : this.props
                                                  .Practitioner_Onsight_Fullread_UpdatedData
                                                  ?.data._key
                                            : data._key,
                                        Onsite_Doctor__id:
                                          typeof index === "undefined"
                                            ? Array.isArray(
                                                this.props
                                                  .Practitioner_Onsight_Fullread_UpdatedData
                                                  ?.data
                                              )
                                              ? this.props
                                                  .Practitioner_Onsight_Fullread_UpdatedData
                                                  ?.data[0]._id
                                              : this.props
                                                  .Practitioner_Onsight_Fullread_UpdatedData
                                                  ?.data._id
                                            : data._id,
                                      },
                                    });
                                  }}
                                  // class={"icon-material-edit-hNcsxv"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770456"
                                  }
                                  alt={"icon-material-edit@1x.png"}
                                ></img>
                                 <img
                                  key={"0"}
                                  onClick={(e) => this.onclick_tjyam(e, data)}
                                  style={{ width: "12px",marginLeft:"10px" }}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770449"
                                  }
                                  alt={"icon-material-delete@1x.png"}
                                ></img>
                              </div>
                            

                          </td>

                        </tr>
                          // <React.Fragment key={index}>
                          //   <div key={"0"} class={"listitem-QxOk3q"}>
                          //     <div key={"0"} class={"group-93716-9ROphj"}>
                          //       <img
                          //         key={"0"}
                          //         onClick={(e) => this.onclick_tjyam(e, data)}
                          //         class={"icon-material-delete-hNcsxv"}
                          //         src={
                          //           "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770449"
                          //         }
                          //         alt={"icon-material-delete@1x.png"}
                          //       ></img>
                          //       <img
                          //         key={"1"}
                          //         onClick={() => {
                          //           this.props.history.push({
                          //             pathname: "/edit_onsite_doctor",
                          //             state: {
                          //               Onsite_Doctor__key:
                          //                 typeof index === "undefined"
                          //                   ? Array.isArray(
                          //                       this.props
                          //                         .Practitioner_Onsight_Fullread_UpdatedData
                          //                         ?.data
                          //                     )
                          //                     ? this.props
                          //                         .Practitioner_Onsight_Fullread_UpdatedData
                          //                         ?.data[0]._key
                          //                     : this.props
                          //                         .Practitioner_Onsight_Fullread_UpdatedData
                          //                         ?.data._key
                          //                   : data._key,
                          //               Onsite_Doctor__id:
                          //                 typeof index === "undefined"
                          //                   ? Array.isArray(
                          //                       this.props
                          //                         .Practitioner_Onsight_Fullread_UpdatedData
                          //                         ?.data
                          //                     )
                          //                     ? this.props
                          //                         .Practitioner_Onsight_Fullread_UpdatedData
                          //                         ?.data[0]._id
                          //                     : this.props
                          //                         .Practitioner_Onsight_Fullread_UpdatedData
                          //                         ?.data._id
                          //                   : data._id,
                          //             },
                          //           });
                          //         }}
                          //         class={"icon-material-edit-hNcsxv"}
                          //         src={
                          //           "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770456"
                          //         }
                          //         alt={"icon-material-edit@1x.png"}
                          //       ></img>
                          //     </div>
                          //     <div
                          //       key={"1"}
                          //       class={
                          //         "administrator-9ROphj roboto-normal-dove-gray-11px"
                          //       }
                          //     >
                          //       {data?.Practitioner_Name}
                          //     </div>
                          //     <div
                          //       key={"2"}
                          //       class={
                          //         "cardiology-9ROphj roboto-normal-dove-gray-11px"
                          //       }
                          //     >
                          //       {data?.Specialty_ID}
                          //     </div>
                          //     <div
                          //       key={"3"}
                          //       class={
                          //         "malaysia-9ROphj roboto-normal-dove-gray-11px"
                          //       }
                          //     >
                          //       {data?.Location}
                          //     </div>
                          //     <div
                          //       key={"4"}
                          //       class={
                          //         "adminainqacom-9ROphj roboto-normal-dove-gray-11px"
                          //       }
                          //     >
                          //       {data?.Practitioner_Mail_ID}
                          //     </div>
                          //     <div
                          //       key={"5"}
                          //       class={
                          //         "malaysia-0HEekk roboto-normal-dove-gray-11px"
                          //       }
                          //     >
                          //       {data?.Address}
                          //     </div>
                          //     <div
                          //       key={"6"}
                          //       class={
                          //         "x1234567890-9ROphj roboto-normal-dove-gray-11px"
                          //       }
                          //     >
                          //       {data?.Phone_Number}
                          //     </div>
                          //     <img
                          //       key={"7"}
                          //       class={"line-3-9ROphj"}
                          //       src={
                          //         "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770463"
                          //       }
                          //       alt={"line-3@1x.png"}
                          //     ></img>
                          //   </div>
                          //  </React.Fragment>
                        );
                      })}
                    {data?.result}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}

const mapStateToProps = (state) => ({
  Practitioner_Onsight_Fullread_UpdatedData:
    state?.Onsite_DoctorPractitioner_Onsight_Fullread_Updated_QtQFVReducer
      ?.Practitioner_Onsight_Fullread_Updated_QtQFV?.data,
  Practitioner_SoftDeleteData:
    state?.Onsite_DoctorPractitioner_SoftDelete_TJyamReducer
      ?.Practitioner_SoftDelete_TJyam?.data,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(OnsiteDoctor)));
