import React from "react";
import { actions } from "realwarebinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import "./index.css";
import { languageOptions, fieldValidator } from "../../utils";
import { LoggerContext } from "../../contexts";
import { withAllContexts } from "../../HOCs";
import { Logout } from "../../components";
import * as ALL from "../../components";

import { div, TextInput, Dropdown } from "qdm-component-library";

class EditSpecialistDoctor extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      edit_specialist_doctor_bdgen: null,
      edit_specialist_doctor_011fe: null,
      edit_specialist_doctor_lbh3a: null,
      edit_specialist_doctor_uigpe: null,
      edit_specialist_doctor_g4kjp: null,
      edit_specialist_doctor_hzr0m: null,
      edit_specialist_doctor_w4ain: null,
      edit_specialist_doctor_dfbde: null,
      validations: {
        DoctorName: true,
        Speciality: true,
        Location: true,
        EmailId: true,
        MobileNumber: true,
        Address: true,
      },
      isError: [],
      isEmail: []
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onchange_011fe = (e) => {
    let result = fieldValidator("name", e.target.value);
    if (this.state.isError?.includes("DoctorName")) {
      this.setState({
        ...this.state,
        isError: this.state.isError.filter(v => v !== 'DoctorName')
      });
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          DoctorName: true,
        },
        edit_specialist_doctor_011fe: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          DoctorName: false,
        },
        edit_specialist_doctor_011fe: e?.target?.value,
      });
    }
  };

  onchange_lbh3a = (e) => {
    let result = fieldValidator("stringWithNumber", e?.label);
    if (this.state.isError?.includes("Speciality")) {
      this.setState({
        ...this.state,
        isError: this.state.isError.filter(v => v !== 'Speciality')
      });
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          Speciality: true,
        },
        edit_specialist_doctor_lbh3a: e,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          Speciality: false,
        },
        edit_specialist_doctor_lbh3a: e,
      });
    }
  };

  onchange_uigpe = (e) => {
    let result = fieldValidator("string", e?.label);
    if (this.state.isError?.includes("Location")) {
      this.setState({
        ...this.state,
        isError: this.state.isError.filter(v => v !== 'Location')
      });
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          Location: true,
        },
        edit_specialist_doctor_uigpe: e,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          Location: false,
        },
        edit_specialist_doctor_uigpe: e,
      });
    }
  };

  onchange_g4kjp = (e) => {
    let result = fieldValidator("emailId", e.target.value);
    if (this.state.isError?.includes("EmailId")) {
      this.setState({
        ...this.state,
        isError: this.state.isError.filter(v => v !== 'EmailId')
      });
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          EmailId: true,
        },
        edit_specialist_doctor_g4kjp: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          EmailId: false,
        },
        edit_specialist_doctor_g4kjp: e?.target?.value,
      });
    }
  };

  onchange_hzr0m = (e) => {
    let result = fieldValidator("mobileNumber", e.target.value);
    if (this.state.isError?.includes("MobileNumber")) {
      this.setState({
        ...this.state,
        isError: this.state.isError.filter(v => v !== 'MobileNumber')
      });
    }
    if (!this.state.edit_specialist_doctor_hzr0m) {
      e.target.value = "01" + e?.target?.value
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          MobileNumber: true,
        },
        edit_specialist_doctor_hzr0m: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          MobileNumber: false,
        },
        edit_specialist_doctor_hzr0m: e?.target?.value,
      });
    }
  };

  onchange_w4ain = (e) => {
    let result = fieldValidator("address", e.target.value);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          Address: true,
        },
        edit_specialist_doctor_w4ain: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          Address: false,
        },
        edit_specialist_doctor_w4ain: e?.target?.value,
      });
    }
  };

  onclick_dfbde = async (event, data) => {
    
    const payload0 = {
      edit_specialist_doctor_history: this.state.edit_specialist_doctor_history,

      edit_specialist_doctor_011fe: this.state.edit_specialist_doctor_011fe,

      edit_specialist_doctor_lbh3a:
        this.state.edit_specialist_doctor_lbh3a?.label,

      edit_specialist_doctor_uigpe:
        this.state.edit_specialist_doctor_uigpe?.label,

      edit_specialist_doctor_w4ain: this.state.edit_specialist_doctor_w4ain,

      edit_specialist_doctor_hzr0m: this.state.edit_specialist_doctor_hzr0m,

      edit_specialist_doctor_g4kjp: this.state.edit_specialist_doctor_g4kjp,
      history: this.props.history.location.state,
      data: data,
    };

    const _ = this.state.isEmail.filter(v => v === this.state.edit_specialist_doctor_g4kjp)
    if (_?.length > 0 && ((
      this.props.Practitioner_Specialist_Singleread_UpdatedData?.result?.[0]?.Practitioner_Mail_ID
    ) !== _?.[0])) {
      this.setState({ ...this.state, isError: [...this.state.isError, 'EmailId'] })
      return this.props.alert.setSnack({
        open: true,
        msg: `Your email id is already exit`,
        severity: "error",
      });
    }

    if (!Object.values(this.state.validations).every((val) => val === true)) {
      let result = Object.entries(this.state.validations).reduce(
        (allVal, val) => {
          if (val[1] === false) {
            return [val[0], ...allVal];
          } else {
            return [...allVal];
          }
        },
        []
      );

      if (this.state.edit_specialist_doctor_g4kjp && (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.edit_specialist_doctor_g4kjp))) {
        this.setState({ ...this.state, isError: [...this.state.isError, 'EmailId'] })
        return this.props.alert.setSnack({
          open: true,
          msg: `Enter the valid email id`,
          severity: "error",
        });
      }

      this.setState({ ...this.state, isError: [...result, ...this.state.isError] })
      return this.props.alert.setSnack({
        open: true,
        msg: `Input fields-${result.join(", ")} are not in the required format`,
        severity: "error",
      });
    }

    let data0 =
      await this.props.Edit_Specialist_DoctorPractitioner_Update_Updated_dFBDE(
        payload0
      );
    if (data0?.payload?.Code === 200) {
      window.location.href = "/specialist_doctor";
    }
  };
  async componentDidMount() {
    /// sample localstorage translation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }

    const payload = { history: this.props.history.location.state };
    this.props.Edit_Specialist_DoctorPractitioner_Specialist_Singleread_Updated_Bdgen(
      payload
    );
    this.props.SpecialityName({ keyName: "Specialty_Name", valueName: "_id" });
    const data = await this.props.Specialist_DoctorPractitioner_Specialist_Fullread_Updated_wLNCN();
    if (!data.payload.result?.length > 0) { return }
    let arr = [];
    data.payload.result.filter(v => arr.push(v.Practitioner_Mail_ID))
    this.setState({ ...this.state, isEmail: arr });
  }

  shouldComponentUpdate(props, state) {
    if (
      this.props?.Practitioner_Update_UpdatedData?.loading !==
      props?.Practitioner_Update_UpdatedData?.loading
    ) {
      if (
        !props?.Practitioner_Update_UpdatedData?.loading &&
        props?.Practitioner_Update_UpdatedData.error
      ) {
      } else if (
        !props?.Practitioner_Update_UpdatedData?.loading &&
        !props?.Practitioner_Update_UpdatedData.error
      ) {
      }
    }

    return true;
  }

  render() {
    const {
      PractitionerData,
      Practitioner_Specialist_Singleread_UpdatedData,
      Practitioner_Update_UpdatedData,
      specialityNameOptions,
      data,
    } = this.props;
    const { isError } = this.state;
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <div key={"0"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"edit-specialist-doctor"}
              ></input>
            </div>
            <div key={"1"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"edit-specialist-doctor"}
              ></input>
              <div key={"1"} class={"edit-specialist-doctor screen"}>
                <div key={"0"} class={"group-93711-kfI6o0"}>
                  <div key={"0"} class={"rectangle-47173-Fu7qyp"}></div>
                  <div key={"1"} class={"group-93715-Fu7qyp"}>
                    <div key={"0"} class={"logo-CIxoKX"}>
                      <img
                        key={"0"}
                        class={"x51-client_profile_subscription-10-M3aVii"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739820"
                        }
                        alt={"5-1-client-profile-subscription---10@1x.png"}
                      ></img>
                      <div key={"1"} class={"global-doctors-M3aVii"}>
                        <span key={"0"} class={"span0-EOPPM4"}>
                          Global
                        </span>
                        <span key={"1"} class={"span1-EOPPM4"}>
                          Doctors
                        </span>
                      </div>
                    </div>
                    <div key={"1"} class={"login-details-CIxoKX"}>
                      <div key={"0"} class={"hello-Ct8Itd"}>
                        Hello{" "}
                      </div>
                      <div key={"1"} class={"mr-rakin-Ct8Itd"}>
                        {sessionStorage.getItem("name")}
                      </div>
                      <div key={"2"} class={"sathishrakinainqacom-Ct8Itd"}>
                        {sessionStorage.getItem("username")}
                      </div>
                      <img
                        key={"3"}
                        class={"line-9-Ct8Itd"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744502"
                        }
                        alt={"line-9@1x.png"}
                      ></img>
                    </div>
                    <div key={"2"} class={"reports-CIxoKX"}>
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/reports");
                        }}
                        class={"reports-3c1x4w roboto-medium-dove-gray-14px"}
                        style={{ cursor: "pointer" }}
                      >
                        Reports
                      </div>
                      <img
                        key={"1"}
                        class={"download-7-3c1x4w"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744506"
                        }
                        alt={"download--7-@1x.png"}
                      ></img>
                    </div>
                    <div key={"4"} class={"rectangle-47179-CIxoKX"}></div>
                    <div key={"5"} class={"master-CIxoKX"}>
                      <div
                        key={"0"}
                        class={"master-IJUCAc"}
                        style={{ cursor: "pointer" }}
                      >
                        Master
                      </div>
                      <img
                        key={"1"}
                        class={"material-master-data-IJUCAc"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744545"
                        }
                        alt={
                          "material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"
                        }
                      ></img>
                    </div>
                    <div key={"6"} class={"group-93714-CIxoKX"}>
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/visit_registration");
                        }}
                        class={
                          "visit-registration-DGO3Nu roboto-medium-dove-gray-14px"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Visit Registration
                      </div>
                      <img
                        key={"1"}
                        class={"download-6-DGO3Nu"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744562"
                        }
                        alt={"download--6-@1x.png"}
                      ></img>
                    </div>
                  </div>
                </div>
                <div key={"1"} class={"version-kfI6o0"}>
                  <div key={"0"} class={"v1001-eZBUGG"}>
                    {"v" + localStorage?.version ?? ""}
                  </div>
                </div>
                <img
                  key={"2"}
                  class={"line-8-kfI6o0"}
                  src={
                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770470"
                  }
                  alt={"line-8@1x.png"}
                ></img>
                <div
                  key={"3"}
                  onClick={() => {
                    this.props.history.push("/onsite_doctor");
                  }}
                  class={"onsite-doctor-medical-assistant-kfI6o0"}
                  style={{ cursor: "pointer" }}
                >
                  Onsite Doctor/Medical Assistant
                </div>
                <div key={"4"} class={"specialist-doctors-kfI6o0"}>
                  <div
                    key={"0"}
                    class={"specialist-doctor-xQx0ub"}
                    style={{ cursor: "pointer" }}
                  >
                    Specialist Doctor
                  </div>
                  <img
                    key={"1"}
                    class={"rectangle-43868-xQx0ub"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770484"
                    }
                    alt={"rectangle-43868@1x.png"}
                  ></img>
                </div>
                <div
                  key={"5"}
                  onClick={() => {
                    this.props.history.push("/patient_facility");
                  }}
                  class={"patient-facility-kfI6o0"}
                  style={{ cursor: "pointer" }}
                >
                  Patient Facility
                </div>
                <div key={"6"} class={"group-93712-kfI6o0"}>
                  <div key={"0"} class={"rectangle-47180-rnzVgE"}></div>
                  <div key={"1"} class={"login-avatar-rnzVgE"}>
                    <Logout />
                  </div>
                  <div key={"2"} class={"master-rnzVgE"}>
                    Master
                  </div>
                </div>
                <div key={"7"} class={"group-93704-kfI6o0"}>
                  <div key={"0"} class={"rectangle-47165-sxUO8R"}></div>
                  <div key={"1"} class={"edit-specialist-doctor-sxUO8R"}>
                    {t("Edit Specialist Doctor")}
                  </div>
                  <img
                    key={"2"}
                    class={"line-3-sxUO8R"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770463"
                    }
                    alt={"line-3@1x.png"}
                  ></img>
                  <div
                    key={"3"}
                    class={"qdm-list-sxUO8R"}
                    style={qdmstyles.tWjbC}
                  >
                    {Array.isArray(
                      Practitioner_Specialist_Singleread_UpdatedData?.["result"]
                    ) &&
                      Practitioner_Specialist_Singleread_UpdatedData?.[
                        "result"
                      ]?.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div key={"0"} class={"listitem-7f9WOl"}>
                              <div key={"0"} class={"qdm-textinput-LAnbvV"}>
                                <TextInput
                                  key={"0"}
                                  onChange={(e) => this.onchange_011fe(e)}
                                  type={"text"}
                                  name={t("text")}
                                  variant={"outlined"}
                                  helperTextType={"error"}
                                  label={t("Doctor Name")}
                                  error={isError?.includes("DoctorName")}
                                  labelStyle={{
                                    fontSize: "12px",
                                    marginBottom: "5px",
                                    color: "gray",
                                  }}
                                  autoFocus={false}
                                  autoComplete={"off"}
                                  search={false}
                                  borderColor={"#9b9b9b"}
                                  elevation={"0"}
                                  placeholder={""}
                                  size={"small"}
                                  isRequired={true}
                                  hoverColor={"#000"}
                                  dateIcon={""}
                                  maxLength={"100"}
                                  iconText={""}
                                  minLength={"100"}
                                  value={
                                    this.state.edit_specialist_doctor_011fe ??
                                    data?.Practitioner_Name
                                  }
                                ></TextInput>
                              </div>
                              <div key={"1"} class={"qdm-textinput-CkG3wH"}>
                                <TextInput
                                  key={"0"}
                                  onChange={(e) => this.onchange_g4kjp(e)}
                                  type={"text"}
                                  name={t("text")}
                                  variant={"outlined"}
                                  helperTextType={"error"}
                                  label={t("Email Id")}
                                  error={isError?.includes("EmailId")}
                                  labelStyle={{
                                    fontSize: "12px",
                                    marginBottom: "5px",
                                    color: "gray",
                                  }}
                                  autoFocus={false}
                                  autoComplete={"off"}
                                  search={false}
                                  borderColor={"#9b9b9b"}
                                  elevation={"0"}
                                  placeholder={""}
                                  size={"small"}
                                  isRequired={true}
                                  hoverColor={"#000"}
                                  dateIcon={""}
                                  maxLength={"100"}
                                  iconText={""}
                                  minLength={"100"}
                                  value={
                                    this.state.edit_specialist_doctor_g4kjp ??
                                    data?.Practitioner_Mail_ID
                                  }
                                ></TextInput>
                              </div>
                              <div key={"2"} class={"qdm-textinput-chUDgh"}>
                                <TextInput
                                  key={"0"}
                                  onChange={(e) => this.onchange_w4ain(e)}
                                  type={"text"}
                                  name={t("text")}
                                  variant={"outlined"}
                                  helperTextType={"error"}
                                  label={t("Address")}
                                  labelStyle={{
                                    fontSize: "12px",
                                    marginBottom: "5px",
                                    color: "gray",
                                  }}
                                  autoFocus={false}
                                  autoComplete={"off"}
                                  search={false}
                                  borderColor={"#9b9b9b"}
                                  elevation={"0"}
                                  placeholder={""}
                                  size={"small"}
                                  hoverColor={"#000"}
                                  dateIcon={""}
                                  maxLength={"60"}
                                  iconText={""}
                                  minLength={"0"}
                                  value={
                                    this.state.edit_specialist_doctor_w4ain ??
                                    data?.Address
                                  }
                                ></TextInput>
                              </div>
                              <div key={"3"} class={"qdm-dropdown-LAnbvV"}>
                                <Dropdown
                                  key={"0"}
                                  onChange={(e) => this.onchange_uigpe(e)}
                                  elevation={"1"}
                                  fullWidth={true}
                                  error={isError?.includes("Location")}
                                  labelStyle={{
                                    fontSize: "12px",
                                    marginBottom: "5px",
                                    color: "gray",
                                    marginTop: "0px",
                                  }}
                                  dropDownTextSize={"14"}
                                  dropDownTextColor={"#000"}
                                  activeTextColor={"#000"}
                                  dropdownListMargin={"0"}
                                  dropdownListPadding={"8"}
                                  options={[
                                    { value: "Chennai", label: "Chennai" },
                                    {
                                      value: "Coimbatore",
                                      label: "Coimbatore",
                                    },
                                    { value: "Madurai", label: "Madurai" },
                                    { value: "Trichy", label: "Trichy" },
                                    { value: "Kochi", label: "Kochi" },
                                    {
                                      value: "Kuala Lumpur",
                                      label: "Kuala Lumpur",
                                    },
                                  ]}
                                  inLineStyles={{ border: isError?.includes("Location") && "1px solid red", "padding": "4px 3px", "fontSize": "14px" }}
                                  label={t("Location")}
                                  isRequired={true}
                                  Url={""}
                                  keyName={""}
                                  valueName={""}
                                  value={
                                    this.state.edit_specialist_doctor_uigpe ?? {
                                      label: data?.Location,
                                    }
                                  }
                                  inLineStyles={qdmstyles.uIgpe}
                                ></Dropdown>
                              </div>
                              <div key={"4"} class={"qdm-dropdown-CkG3wH"}>
                                {specialityNameOptions && (
                                  <Dropdown
                                    key={"0"}
                                    onChange={(e) => this.onchange_lbh3a(e)}
                                    elevation={"1"}
                                    fullWidth={true}
                                    labelStyle={{
                                      fontSize: "12px",
                                      marginBottom: "5px",
                                      color: "gray",
                                      marginTop: "0px",
                                    }}
                                    dropDownTextSize={"14"}
                                    dropDownTextColor={"#000"}
                                    activeTextColor={"#000"}
                                    dropdownListMargin={"0"}
                                    dropdownListPadding={"8"}
                                    options={specialityNameOptions}
                                    label={t("Speciality")}
                                    error={isError?.includes("Speciality")}
                                    inLineStyles={{ border: isError?.includes("Speciality") && "1px solid red", "padding": "4px 3px", "fontSize": "14px" }}
                                    isRequired={true}
                                    Url={""}
                                    keyName={""}
                                    valueName={""}
                                    value={
                                      this.state
                                        .edit_specialist_doctor_lbh3a ?? {
                                        label: data?.Specialty_ID,
                                      }
                                    }
                                    inLineStyles={qdmstyles.LBha}
                                  ></Dropdown>
                                )}
                              </div>
                              <div key={"5"} class={"qdm-textinput-wd0eFl"}>
                                <TextInput
                                  key={"0"}
                                  onChange={(e) => this.onchange_hzr0m(e)}
                                  type={"text"}
                                  name={t("text")}
                                  variant={"outlined"}
                                  helperTextType={"error"}
                                  label={t("Mobile Number")}
                                  labelStyle={{
                                    fontSize: "12px",
                                    marginBottom: "5px",
                                    color: "gray",
                                  }}
                                  autoFocus={false}
                                  autoComplete={"off"}
                                  search={false}
                                  borderColor={"#9b9b9b"}
                                  elevation={"0"}
                                  placeholder={""}
                                  size={"small"}
                                  isRequired={true}
                                  hoverColor={"#000"}
                                  dateIcon={""}
                                  maxLength={10}
                                  iconText={""}
                                  minLength={10}
                                  value={
                                    this.state.edit_specialist_doctor_hzr0m ??
                                    data?.Phone_Number
                                  }
                                  onKeyDown={(e) => {
                                    const charCode = e.which ? e.which : e.keyCode;
                                    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                      e.preventDefault()
                                      return
                                    }
                                  }}
                                ></TextInput>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    {data?.result}
                  </div>
                  <div key={"4"} class={"group-93708-sxUO8R"}>
                    <div
                      key={"0"}
                      onClick={() => {
                        this.props.history.push("/specialist_doctor");
                      }}
                      class={"back-FQjxLy"}
                      style={{ cursor: "pointer" }}
                    >
                      Back
                    </div>
                    <img
                      key={"1"}
                      class={"path-734-FQjxLy"}
                      src={
                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183772612"
                      }
                      alt={"path-734@1x.png"}
                    ></img>
                  </div>
                  <div key={"5"} class={"qdm-button-sxUO8R"}>
                    <div
                      key={"0"}
                      onClick={(e) => this.onclick_dfbde(e, data)}
                      class={"submit-2MqPqf"}
                      style={{ cursor: "pointer" }}
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}

const mapStateToProps = (state) => ({
  Practitioner_Specialist_Singleread_UpdatedData:
    state
      ?.Edit_Specialist_DoctorPractitioner_Specialist_Singleread_Updated_BdgenReducer
      ?.Practitioner_Specialist_Singleread_Updated_Bdgen?.data,
  Practitioner_Update_UpdatedData:
    state?.Edit_Specialist_DoctorPractitioner_Update_Updated_dFBDEReducer
      ?.Practitioner_Update_Updated_dFBDE?.data,
  specialityNameOptions:
    state.All_Dropdowns_Reducer?.specialityName?.data?.result,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(translate()(EditSpecialistDoctor))));
