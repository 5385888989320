import React from "react";
import { actions } from "realwarebinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal, TextArea } from "qdm-component-library";
import "./index.css";
import { languageOptions, fieldValidator } from "../../utils";
import { LoggerContext } from "../../contexts";
import { withAllContexts } from "../../HOCs";

import { Qrcode, Success } from "../../components";
import { Logout } from "../../components";
import { checkWithCasbin } from "../../utils";
import { Button } from "antd";
import { div, Dropdown, TextInput } from "qdm-component-library";
import { LoadingOutlined } from "@ant-design/icons";

class VisitRegistration extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      visit_registration_zopet: null,
      visit_registration_vgpos: null,
      visit_registration_0zujx: null,
      visit_registration_hzkpz: null,
      visit_registration_lknh: null,
      facility: null,
      facilityModal: false,
      searchBy: null,
      qrCode: "",
      searchResIndex: null,
      permissionData: {
        read: [],
        write: [],
      },
      searchLoader: false,
      patient_registration_lj4kk: null,
      patient_registration_ec1wv: null,
      patient_registration_qlm9w: null,
      patient_registration_ukwmz: null,
      patient_registration_2njp6: null,
      patient_registration_qvcov: null,
      patient_registration_eqt7z: null,
      patient_registration_qdxhq: null,
      patient_registration_qdxhq_9sv6l: null,
      age: null,
      dob: null,
      loading: false,
      gender: null,
      validations: {
        PatientName: false,
        Gender: false,
        IdType: false,
        IdCard: false,
        EmpNo: false,
        MobileNumber: false,
        FacilityName: false,
        DOB: false,
        speciality: false,
        purposeOfVisit: false,
      },
      error: {
        PatientName: false,
        Gender: false,
        IdType: false,
        IdCard: false,
        EmpNo: false,
        MobileNumber: false,
        FacilityName: false,
        DOB: false,
        speciality: false,
        purposeOfVisit: false,
      },
      Facility_Name: "",
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  validationResponse = (data) => {
    let status = Object.keys(data).findIndex((key, index) => {
      if (
        key !== "data" &&
        key !== "history" &&
        key !== "patient_registration_qvcov"
      ) {
        if (!data[key]) {
          this.props.alert.setSnack({
            open: true,
            msg: "Fill In All Required fields",
            severity: "error",
          });
          return true;
        }
      }
    });
    if (status == -1) return false;
    else return true;
  };
  validateIDCard = (type, number) => {
    let pattern = type === "Passport" ? /^[A-Z]\d{8,8}$/g : /[A-Z0-9]{12,12}$/g;
    let result = pattern.test(number);
    return result;
  };

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onchange_lj4kk = (e) => {
    let result = fieldValidator("name", e?.target?.value);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          PatientName: true,
        },
        error: {
          ...this.state.error,
          PatientName: false,
        },
        patient_registration_lj4kk: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          PatientName: false,
        },
        error: {
          ...this.state.error,
          PatientName: false,
        },
        patient_registration_lj4kk: e?.target?.value,
      });
    }
  };

  onchange_ec1wv = (e) => {
    if (!this.state.patient_registration_eqt7z)
      return this.props.alert.setSnack({
        open: true,
        msg: "Please select the ID Type",
        severity: "error",
      });

    let result = this.validateIDCard(
      this.state.patient_registration_eqt7z.value,
      e?.target?.value
    );
    if (this.state.patient_registration_eqt7z?.value === "Passport") {
      if (!this.state.patient_registration_ec1wv) {
        e.target.value = e.target.value?.toUpperCase();
      } else if (+e.target.value.slice(1, e.target.value.length) > 0) {
        e.target.value = e.target.value;
      } else {
        // e.target.value = this.state.patient_registration_ec1wv
        e.target.value = e.target.value?.toUpperCase();
      }
    } else if (this.state.patient_registration_eqt7z?.value === "NRIC") {
      e.target.value = e.target.value?.toUpperCase();
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          IdCard: true,
        },
        error: {
          ...this.state.error,
          IdCard: false,
        },
        patient_registration_ec1wv: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          IdCard: false,
        },
        error: {
          ...this.state.error,
          IdCard: false,
        },
        patient_registration_ec1wv: e?.target?.value,
      });
    }
  };

  onchange_qlm9w = (e) => {
    let result = fieldValidator("number", e?.target?.value);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          EmpNo: true,
        },
        error: {
          ...this.state.error,
          EmpNo: false,
        },
        patient_registration_qlm9w: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          EmpNo: false,
        },
        error: {
          ...this.state.error,
          EmpNo: false,
        },
        patient_registration_qlm9w: e?.target?.value,
      });
    }
  };
  onchange_ukwmz = (e) => {
    let result = fieldValidator("mobileNumber", e?.target?.value);

    if (!this.state.patient_registration_ukwmz) {
      e.target.value = "01" + e?.target?.value;
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          MobileNumber: true,
        },
        error: {
          ...this.state.error,
          MobileNumber: false,
        },
        patient_registration_ukwmz: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          MobileNumber: false,
        },
        error: {
          ...this.state.error,
          MobileNumber: false,
        },
        patient_registration_ukwmz: e?.target?.value,
      });
    }
  };

  onchange_2njp6 = (e) => {
    let result = fieldValidator("stringWithNumber", e?.label);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          FacilityName: true,
        },
        error: {
          ...this.state.error,
          FacilityName: false,
        },
        patient_registration_2njp6: e,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          FacilityName: false,
        },
        error: {
          ...this.state.error,
          FacilityName: false,
        },
        patient_registration_2njp6: e,
      });
    }
  };

  onchange_qvcov = (e) => {
    let result = e?.target?.value;
    this.setState({
      patient_registration_qvcov: e?.target?.value,
    });
  };

  handleSubmitFacility = (e) => {
    sessionStorage.setItem("facility", this.state.facility._id);
    this.setState({ facilityModal: false });
    this.props.alert.setSnack({
      open: true,
      msg: `Facility selected`,
      severity: "success",
    });
  };

  convertDateToAge = (dob) => {
    var Bday = +new Date(dob);
    return (Date.now() - Bday) / 31557600000;
  };

  onchange_lj4kk2 = (e) => {
    let value = null;
    if (e.target.value) value = this.convertDateToAge(e.target.value);
    if (value) {
      this.setState({
        validations: {
          ...this.state.validations,
          DOB: true,
        },
        error: {
          ...this.state.error,
          DOB: false,
        },
        age: parseInt(value),
        dob: e.target.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          DOB: false,
        },
        error: {
          ...this.state.error,
          DOB: false,
        },
        age: parseInt(value),
        dob: e.target.value,
      });
    }
  };

  onchange_eqt7z = (e) => {
    let result = fieldValidator("stringWithNumber", e?.value);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          IdType: true,
        },
        error: {
          ...this.state.error,
          IdType: false,
        },
        patient_registration_eqt7z: e,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          IdType: false,
        },
        error: {
          ...this.state.error,
          IdType: false,
        },
        patient_registration_eqt7z: e,
      });
    }
  };
  onchange_lj4kk1 = (e) => {
    let result = fieldValidator("stringWithNumber", e?.label);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          Gender: true,
        },
        error: {
          ...this.state.error,
          Gender: false,
        },
        gender: e,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          Gender: false,
        },
        error: {
          ...this.state.error,
          Gender: false,
        },
        gender: e,
      });
    }
    // let result = fieldValidator("stringWithNumber", e?.value);
    // if (result) {
    //   this.setState({
    //     validations: {
    //       ...this.state.validations,
    //       IdType: true,
    //     },
    //     gender: e,
    //   });
    // } else {
    //   this.setState({
    //     validations: {
    //       ...this.state.validations,
    //       IdType: false,
    //     },
    //     gender: e,
    //   });
    // }
  };

  onclick_qdxhq = async (event, data) => {
    const payload0 = {
      patient_registration_eqt7z: this.state.patient_registration_eqt7z?.label,

      patient_registration_ec1wv: this.state.patient_registration_ec1wv,

      patient_registration_ukwmz: this.state.patient_registration_ukwmz,

      patient_registration_lj4kk: this.state.patient_registration_lj4kk,

      patient_registration_qlm9w: this.state.patient_registration_qlm9w,

      patient_registration_2njp6: this.state.patient_registration_2njp6?.value,

      patient_registration_qvcov: this.state.patient_registration_qvcov,

      age: this.state.age,

      dob: this.state.dob,
      gender: this.state.gender?.value,

      history: this.props.history.location.state,
      data: data,
    };

    if (!Object.values(this.state.validations).every((val) => val === true)) {
      let result = Object.entries(this.state.validations).reduce(
        (allVal, val) => {
          if (val[1] === false) {
            this.state.error[val[0]] = true;
            return [val[0], ...allVal];
          } else {
            return [...allVal];
          }
        },
        []
      );
      return this.props.alert.setSnack({
        open: true,
        msg: `Input fields-${result.join(", ")} are not in the required format`,
        severity: "error",
      });
    }
    let data0 = await this.props.Patient_RegistrationPatient_Insert_Query_QdXHq(
      payload0
    );

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        db_name: "Real_Wear",
        entity: "Facility",
        filter: `Facility.activestatus==true && Facility._id=='${data0?.payload?.Result[0]?.properties?.doc?.Facility_ID}'`,
        return_fields: "Facility",
      }),
    };

    await fetch(
      "https://arangodbservice.dev.ainqaplatform.in/api/read_documents",
      requestOptions
    )
      .then((response) => response.json())
      .then((data_) => {
        this.setState({
          ...this.state,
          Facility_Name: data_?.result?.[0]?.Facility_Name ?? "",
        });
      });

    if (data0?.payload?.Code === 200) {
      this.setState({
        patient_registration_qdxhq_9sv6l: true,
        SuccessState: data0?.payload?.Result[0]?.properties?.doc,
      });
    }

    let res = data0?.payload?.Result[0]?.properties?.doc;
    return res;
  };

  searchResIndex = (index) => {
    this.setState({ searchResIndex: index });
  };

  visitFlow = async () => {};

  onclick_vgpos = async (event, data) => {
    this.setState({
      searchResIndex: null,
      visit_registration_hzkpz: null,
      visit_registration_lknh: null,
      loading: true,
      searchLoader: true,
    });
    const payload0 = {
      visit_registration_zopet: this.state.visit_registration_zopet,
      history: this.props.history.location.state,
      data: data,
    };
    if (this.validationResponse(payload0)) {
      return true;
    }
    if (payload0.visit_registration_zopet)
      payload0.visit_registration_zopet += "%";
    let data0 = await this.props.Visit_RegistrationPatient_Search_vgpoS(
      payload0
    );
    if (data0) {
      this.setState({
        loading: false,
      });
    }
  };

  onchange_zopet = async (e, data) => {
    // if (!this.state.searchBy) return;
    this.setState({ visit_registration_zopet: e?.target?.value });
    // const payload0 = {
    //   visit_registration_zopet: e.target.value,
    //   history: this.props.history.location.state,
    //   data: data,
    // };
    // if (payload0.visit_registration_zopet)
    // payload0.visit_registration_zopet += "%";
    // let data0 = await this.props.Visit_RegistrationPatient_Search_vgpoS(
    //   payload0
    // );
  };
  // aaa
  onclick_0zujx = async (event, data, newPatient) => {
    let result =
      this.props.Patient_SearchData?.["result"][this.state.searchResIndex ?? 0];
    if (this.state.SuccessState?._id) {
      result = this.state.SuccessState;
    }
    if (newPatient & !result?._id) {
      result = await this.onclick_qdxhq(event, data, newPatient);
      if (!result?._id) return;
    }
    if (!result?._id) return;
    let payload0 = {
      visit_registration_hzkpz: this.state.visit_registration_hzkpz?.value,
      visit_registration_lknh: this.state.visit_registration_lknh?.value,
      history: this.props.history.location.state,
      facilityId: this.state.facility,
      data: result,
    };
    if (
      !this.state.visit_registration_lknh?.label ||
      !this.state.visit_registration_hzkpz?.Practitioner_Name
    ) {
      if (!this.state.visit_registration_lknh?.label) {
        this.state.error.speciality = true;
      }
      if (!this.state.visit_registration_lknh?.label) {
        this.state.error.purposeOfVisit = true;
      }
      return this.props.alert.setSnack({
        open: true,
        msg: `Fill In All Required fields`,
        severity: "error",
      });
      return true;
    }

    if (this.validationResponse(payload0)) {
      return true;
    }
    this.setState({
      visit_registration_0zujx_osqne: true,
    });

    document.body.style.overflow = "hidden";

    let payload1 = { history: this.props.history.location.state, data: data };

    payload1 = {
      hostName: sessionStorage?.name,
      hostEmail: sessionStorage?.username,
      guestName: this.state?.visit_registration_hzkpz?.label,
      guestEmail: this.state?.visit_registration_hzkpz?.Practitioner_Mail_ID, //guess email key (not fixed)
      ...payload1,
    };
    let data1 = await this.props.Visit_Registrationavc_meet_0ZUjx(payload1);

    payload0.record = data1?.payload?.name;

    let data0 = await this.props.Visit_RegistrationEncounter_Insert_0ZUjx(
      payload0
    );

    if (data0?.payload?.Code === 200 && data1?.payload) {
      this.setState({
        qrCode: data1?.payload?.moderatorQRCode,
        loading: false,
      });

      //// updating encounter payload

      delete payload0.Facility_ID;
      payload0 = {
        ...payload0,
        Meeting_ID: `${data1?.payload?.name}`,
        AVC_Patient_URL: data1?.payload?.moderatorUrl,
        AVC_Practitioner_URL: data1?.payload?.guestUrl,
        Encounter_Number:
          data0?.payload?.Result[0]?.properties?.doc?.Encounter_Number ?? "",
        _id: data0?.payload?.Result[0]?.properties?.doc?._id,
      };
      let encounterUpdate =
        await this.props.Visit_RegistrationEncounter_Insert_0ZUjx(payload0);
    }
  };
  onchange_hzkpz = (e) => {
    this.setState({
      validations: {
        ...this.state.validations,
        speciality: true,
      },
      error: {
        ...this.state.error,
        speciality: false,
      },
      visit_registration_hzkpz: e,
    });
  };
  onchange_ukwmz = (e) => {
    let result = fieldValidator("mobileNumber", e?.target?.value);

    if (!this.state.patient_registration_ukwmz) {
      e.target.value = "01" + e?.target?.value;
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          MobileNumber: true,
        },
        error: {
          ...this.state.error,
          MobileNumber: false,
        },
        patient_registration_ukwmz: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          MobileNumber: false,
        },
        error: {
          ...this.state.error,
          MobileNumber: false,
        },
        patient_registration_ukwmz: e?.target?.value,
      });
    }
  };

  onchange_hzkpznj = (e) => {
    this.setState({ searchBy: e?.label });
  };

  onchange_lknh = (e) => {
    let result = fieldValidator("stringWithNumber", e?.label);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          purposeOfVisit: true,
        },
        error: {
          ...this.state.error,
          purposeOfVisit: false,
        },
        visit_registration_lknh: e,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          purposeOfVisit: false,
        },
        error: {
          ...this.state.error,
          purposeOfVisit: false,
        },
        visit_registration_lknh: e,
      });
    }

    // this.setState({
    //   validations: {
    //     ...this.state.validations,
    //     purposeOfVisit: true,
    //   },
    //   error: {
    //     ...this.state.error,
    //     purposeOfVisit: false,
    //   },
    //   visit_registration_lknh: e,
    // });
  };

  getFacilityValue = (id) => {
    let facility = this.props?.facilityNameOptions.find((option) => {
      return option._id === id;
    });
    return facility;
  };

  async componentDidMount() {
    /// sample localstorage translation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    this.props.SpecialityDoctors({
      keyName: "Practitioner_Name",
      valueName: "Practitioner_ID",
    });
    this.props.PurposeOfVisit({ keyName: "POV_Name", valueName: "_id" });
    this.props.FacilityName({ keyName: "Facility_Name", valueName: "_id" });

    //// casbin setter
    const permission = await checkWithCasbin(["visit_registration"]);
    this.setState({ permissionData: permission });
    if (!sessionStorage.getItem("facility")) {
      this.setState({ facilityModal: true });
      document.body.overflow = "hidden";
    } else {
      this.setState({ facility: sessionStorage.getItem("facility") });
    }
  }

  shouldComponentUpdate(props, state) {
    if (
      this.props?.Encounter_InsertData?.loading !==
      props?.Encounter_InsertData?.loading
    ) {
      if (
        !props?.Encounter_InsertData?.loading &&
        props?.Encounter_InsertData.error
      ) {
      } else if (
        !props?.Encounter_InsertData?.loading &&
        !props?.Encounter_InsertData.error
      ) {
      }
    }

    return true;
  }

  render() {
    const {
      PatientData,
      Patient_SearchData,
      Encounter_InsertData,
      data,
      specialityDoctorsOptions,
      purposeOfVisitOptions,
      facilityNameOptions,
    } = this.props;

    if (!sessionStorage.facility) {
      setTimeout(() => {
        document.body.style.overflow = "hidden";
      }, 100);
    } else {
      document.body.style.overflow = "auto";
    }

    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <div key={"0"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"visit-registration"}
              ></input>
              <div key={"1"} class={"visit-registration screen"}>
                <div key={"0"} class={"qdm-list-JQaIUO"} style={qdmstyles.wktR}>
                  {/* mutiple patient details */}

                  {Array.isArray(Patient_SearchData?.["result"]) &&
                    // this.state.visit_registration_zopet &&
                    Patient_SearchData?.["result"]?.length > 1 &&
                    this.state?.searchResIndex === null &&
                    !this.state.loading &&
                    this.state.searchLoader && (
                      <React.Fragment>
                        <div key={"0"} class={"listitem-Ci3S5d"}>
                          <div
                            key={"0"}
                            class={
                              "patient-registration-updated rectangle-32-Zxtv06"
                            }
                            style={qdmstyles.XNSfv}
                          >
                            <p style={{ margin: "12px 0px", color: "gray" }}>
                              Search Result :
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {Patient_SearchData?.["result"].map(
                                (item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div
                                        key={"1"}
                                        class={"group-93710-Zxtv06"}
                                        onClick={() =>
                                          this.searchResIndex(index)
                                        }
                                        style={{ margin: "24px" }}
                                      >
                                        <div
                                          key={"0"}
                                          class={
                                            "patient-name-vGUBia roboto-normal-concord-12px"
                                          }
                                          style={{
                                            marginTop: 14,
                                            left: 20,
                                          }}
                                        >
                                          <b>Patient Name</b> :{" "}
                                          {item?.Patient_Name}
                                        </div>
                                        <div
                                          key={"0"}
                                          class={
                                            "patient-name-vGUBia roboto-normal-concord-12px"
                                          }
                                          style={{
                                            marginTop: 37,
                                            marginBottom: 10,
                                            left: 20,
                                          }}
                                        >
                                          <b>EMP Number</b> : {item?.Emp_Number}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                  {/* Patient details */}
                  {Array.isArray(Patient_SearchData?.["result"]) &&
                    // this.state.visit_registration_zopet &&
                    (Patient_SearchData?.["result"]?.length === 1 ||
                      this.state?.searchResIndex != null) &&
                    this.state.searchLoader &&
                    [
                      Patient_SearchData?.["result"][
                        this.state?.searchResIndex
                          ? this.state?.searchResIndex
                          : 0
                      ],
                    ].map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div key={"0"} class={"listitem-Ci3S5d"}>
                            <div
                              key={"0"}
                              class={
                                "patient-registration-updated rectangle-32-Zxtv05"
                              }
                              style={qdmstyles.XNSfv}
                            >
                              <div key={"2"} class={"form-9HxdoZ"}>
                                <div key={"1"} class={"group-93725-Y8vGsH"}>
                                  <div key={"0"} class={"component-28-L99nfx"}>
                                    <div
                                      key={"0"}
                                      class={"qdm-textinput-CLsSDU"}
                                    >
                                      <TextInput
                                        key={"0"}
                                        type={"text"}
                                        name={t("text")}
                                        variant={"outlined"}
                                        helperTextType={"error"}
                                        label={t("Patient Name")}
                                        labelStyle={{
                                          fontSize: "12px",
                                          marginBottom: "6px",
                                          color: "gray",
                                          marginTop: "0px",
                                        }}
                                        autoFocus={false}
                                        autoComplete={"off"}
                                        search={false}
                                        borderColor={"#9b9b9b"}
                                        elevation={"0"}
                                        placeholder={""}
                                        size={"small"}
                                        isRequired={false}
                                        hoverColor={"#000"}
                                        dateIcon={""}
                                        maxLength={"100"}
                                        iconText={""}
                                        minLength={"100"}
                                        value={data?.Patient_Name}
                                        readOnly={true}
                                      ></TextInput>
                                    </div>
                                  </div>
                                  <div key={"1"} class={"component-69-L99nfx"}>
                                    <div
                                      key={"0"}
                                      class={"qdm-textinput-6xHVnp"}
                                    >
                                      <TextArea
                                        key={"0"}
                                        type={"text"}
                                        name={t("text")}
                                        variant={"outlined"}
                                        helperTextType={"error"}
                                        label={t("Remark")}
                                        labelStyle={{
                                          fontSize: "12px",
                                          marginBottom: "6px",
                                          color: "gray",
                                          marginTop: "0px",
                                        }}
                                        autoFocus={false}
                                        autoComplete={"off"}
                                        search={false}
                                        borderColor={"#9b9b9b"}
                                        elevation={"0"}
                                        placeholder={""}
                                        size={"small"}
                                        isRequired={false}
                                        hoverColor={"#000"}
                                        dateIcon={""}
                                        maxLength={"500"}
                                        iconText={""}
                                        value={data?.Remarks}
                                        maxRows={"5"}
                                        readOnly={true}
                                      ></TextArea>
                                    </div>
                                  </div>
                                  <div key={"2"} class={"component-68-L99nfx"}>
                                    <div
                                      key={"0"}
                                      class={"qdm-dropdown-Y59cwJ"}
                                    >
                                      <Dropdown
                                        key={"0"}
                                        elevation={"1"}
                                        fullWidth={true}
                                        dropDownTextSize={"14"}
                                        dropDownTextColor={"#000"}
                                        activeTextColor={"#000"}
                                        dropdownListMargin={"0"}
                                        dropdownListPadding={"8"}
                                        options={facilityNameOptions || []}
                                        label={t("Facility Name")}
                                        onChange={(e) => {
                                          console.log(e);
                                          this.setState({
                                            facility: e,
                                          });
                                        }}
                                        labelStyle={{
                                          fontSize: "12px",
                                          marginBottom: "6px",
                                          color: "gray",
                                          marginTop: "0px",
                                        }}
                                        isRequired={false}
                                        Url={""}
                                        keyName={""}
                                        valueName={""}
                                        placeholder=""
                                        inLineStyles={{
                                          padding: "5px 4px",
                                          fontSize: "14px",
                                          borderRadius: "4px 0px 0px 4px",
                                          borderColor: "#9b9b9b",
                                        }}
                                        value={
                                          data?.Facility_ID
                                            ? this.getFacilityValue(
                                                data?.Facility_ID
                                              )
                                            : {
                                                label: "",
                                                value: "",
                                              }
                                        }
                                      ></Dropdown>
                                      {/* {!facilityNameOptions && (
                                        <Dropdown
                                          key={"0"}
                                          elevation={"1"}
                                          fullWidth={true}
                                          dropDownTextSize={"14"}
                                          dropDownTextColor={"#000"}
                                          activeTextColor={"#000"}
                                          dropdownListMargin={"0"}
                                          dropdownListPadding={"8"}
                                          options={[]}
                                          label={t("Facility Name")}
                                          labelStyle={{
                                            fontSize: "12px",
                                            marginBottom: "6px",
                                            color: "gray",
                                            marginTop: "0px",
                                          }}
                                          isRequired={false}
                                          Url={""}
                                          keyName={""}
                                          valueName={""}
                                          placeholder=""
                                          inLineStyles={{
                                            padding: "5px 4px",
                                            fontSize: "14px",
                                            borderRadius: "4px 0px 0px 4px",
                                            borderColor: "#9b9b9b",
                                          }}
                                          value={{
                                            label: "",
                                          }}
                                        ></Dropdown>
                                      )} */}
                                    </div>
                                  </div>
                                  <div key={"3"} class={"group-93727-L99nfx"}>
                                    <div
                                      key={"0"}
                                      class={"component-78-7XIOuo"}
                                    >
                                      <div
                                        key={"0"}
                                        class={"qdm-dropdown-w3DTLW"}
                                      >
                                        <Dropdown
                                          key={"0"}
                                          elevation={"1"}
                                          fullWidth={true}
                                          dropDownTextSize={"14"}
                                          dropDownTextColor={"#000"}
                                          activeTextColor={"#000"}
                                          dropdownListMargin={"0"}
                                          dropdownListPadding={"8"}
                                          label={t("Gender")}
                                          options={[]}
                                          labelStyle={{
                                            fontSize: "12px",
                                            marginBottom: "6px",
                                            color: "gray",
                                            marginTop: "0px",
                                          }}
                                          isRequired={false}
                                          Url={""}
                                          keyName={""}
                                          valueName={""}
                                          placeholdePr={""}
                                          readOnly={true}
                                          inLineStyles={{
                                            padding: "5px 4px",
                                            fontSize: "14px",
                                            borderColor: "#9b9b9b",
                                          }}
                                          value={{
                                            value: data?.Gender ?? "",
                                            label: data?.Gender ?? "",
                                          }}
                                        ></Dropdown>
                                      </div>
                                    </div>
                                    <div
                                      key={"1"}
                                      class={"component-79-7XIOuo"}
                                    >
                                      <div
                                        key={"0"}
                                        class={"qdm-textinput-ba6bAn"}
                                      >
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            marginBottom: "6px",
                                            color: "gray",
                                            marginTop: "0px",
                                          }}
                                          className={"sc-gsDKAQ gHKREI"}
                                        >
                                          Date of Birth
                                        </p>
                                        <input
                                          type={"date"}
                                          style={{
                                            height: "32px",
                                            width: "100%",
                                          }}
                                          onChange={this.onchange_lj4kk2}
                                          value={data?.DOB}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      key={"2"}
                                      class={"component-80-7XIOuo"}
                                    >
                                      <div
                                        key={"0"}
                                        class={"qdm-textinput-b5GQFF"}
                                      >
                                        <TextInput
                                          key={"0"}
                                          type={"text"}
                                          name={t("text")}
                                          variant={"outlined"}
                                          helperTextType={"error"}
                                          label={t("Age")}
                                          labelStyle={{
                                            fontSize: "12px",
                                            marginBottom: "6px",
                                            color: "gray",
                                            marginTop: "0px",
                                          }}
                                          autoFocus={false}
                                          autoComplete={"off"}
                                          search={false}
                                          borderColor={"#9b9b9b"}
                                          elevation={"0"}
                                          placeholder={""}
                                          size={"small"}
                                          isRequired={false}
                                          hoverColor={"#000"}
                                          dateIcon={""}
                                          maxLength={"100"}
                                          iconText={""}
                                          minLength={"100"}
                                          value={data?.Age}
                                          readOnly={true}
                                        ></TextInput>
                                      </div>
                                    </div>
                                  </div>
                                  <div key={"4"} class={"component-67-L99nfx"}>
                                    <div
                                      key={"0"}
                                      class={"qdm-textinput-IE6RqY"}
                                    >
                                      <TextInput
                                        key={"0"}
                                        type={"text"}
                                        name={t("text")}
                                        variant={"outlined"}
                                        helperTextType={"error"}
                                        label={t("EMP No")}
                                        labelStyle={{
                                          fontSize: "12px",
                                          marginBottom: "6px",
                                          color: "gray",
                                          marginTop: "0px",
                                        }}
                                        autoFocus={false}
                                        autoComplete={"off"}
                                        search={false}
                                        borderColor={"#9b9b9b"}
                                        elevation={"0"}
                                        placeholder={""}
                                        size={"small"}
                                        isRequired={false}
                                        hoverColor={"#000"}
                                        dateIcon={""}
                                        maxLength={"100"}
                                        iconText={""}
                                        minLength={"100"}
                                        value={data?.Emp_Number}
                                        readOnly={true}
                                      ></TextInput>
                                    </div>
                                  </div>
                                  <div
                                    key={"5"}
                                    class={"component-93700-L99nfx"}
                                  >
                                    <div
                                      key={"0"}
                                      class={"qdm-textinput-8Wz9jn"}
                                    >
                                      <TextInput
                                        key={"0"}
                                        type={"text"}
                                        name={t("text")}
                                        variant={"outlined"}
                                        helperTextType={"error"}
                                        label={t("Mobile Number")}
                                        labelStyle={{
                                          fontSize: "12px",
                                          marginBottom: "6px",
                                          color: "gray",
                                          marginTop: "0px",
                                        }}
                                        autoFocus={false}
                                        autoComplete={"off"}
                                        search={false}
                                        borderColor={"#9b9b9b"}
                                        elevation={"0"}
                                        placeholder={""}
                                        size={"small"}
                                        isRequired={false}
                                        hoverColor={"#000"}
                                        dateIcon={""}
                                        maxLength={"10"}
                                        iconText={""}
                                        minLength={"10"}
                                        value={data?.Mobile_Number}
                                        readOnly={true}
                                      ></TextInput>
                                    </div>
                                  </div>
                                  <div
                                    key={"6"}
                                    class={"component-93700-NWHnxr"}
                                  >
                                    <div
                                      key={"0"}
                                      class={"qdm-textinput-UtUZ2W"}
                                    >
                                      <TextInput
                                        key={"0"}
                                        type={"text"}
                                        name={t("ID Card No")}
                                        variant={"outlined"}
                                        helperTextType={"error"}
                                        label={t("ID Card No")}
                                        labelStyle={{
                                          fontSize: "12px",
                                          marginBottom: "6px",
                                          color: "gray",
                                          marginTop: "0px",
                                        }}
                                        autoFocus={false}
                                        autoComplete={"off"}
                                        search={false}
                                        borderColor={"#9b9b9b"}
                                        elevation={"0"}
                                        placeholder={"Enter your ID number"}
                                        size={"small"}
                                        isRequired={false}
                                        hoverColor={"#000"}
                                        dateIcon={""}
                                        maxLength={"100"}
                                        iconText={""}
                                        minLength={"100"}
                                        inLineStyles={{
                                          borderRadius: "0px 4px 0px 0px",
                                        }}
                                        value={data?.Patient_ID_Number}
                                        readOnly={true}
                                      ></TextInput>
                                    </div>
                                    <div
                                      key={"1"}
                                      class={"qdm-dropdown-UtUZ2W"}
                                    >
                                      <Dropdown
                                        key={"0"}
                                        elevation={"1"}
                                        fullWidth={true}
                                        dropDownTextSize={"14"}
                                        dropDownTextColor={"#000"}
                                        activeTextColor={"#000"}
                                        dropdownListMargin={"0"}
                                        dropdownListPadding={"8"}
                                        options={
                                          [
                                            {
                                              value: "Passport",
                                              label: "Passport",
                                            },
                                            {
                                              value: "NRIC",
                                              label: "NRIC",
                                            },
                                          ] || []
                                        }
                                        label={t("ID Type")}
                                        labelStyle={{
                                          fontSize: "12px",
                                          marginBottom: "6px",
                                          color: "gray",
                                          marginTop: "0px",
                                        }}
                                        isRequired={false}
                                        Url={""}
                                        keyName={""}
                                        valueName={""}
                                        name={t("ID Type")}
                                        inLineStyles={{
                                          padding: "5px 4px",
                                          fontSize: "14px",
                                          borderRadius: "4px 0px 0px 4px",
                                          borderColor: "#9b9b9b",
                                        }}
                                        value={{
                                          value: data?.Patient_ID_Type,
                                          label: data?.Patient_ID_Type,
                                        }}
                                      ></Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div key={"2"} class={"component-76-Y8vGsH"}>
                                  <div key={"0"} class={"qdm-dropdown-bFecFd"}>
                                    {specialityDoctorsOptions &&
                                      this.state?.permissionData?.read?.indexOf(
                                        "show_doctor_details"
                                      ) > -1 && (
                                        <Dropdown
                                          key={"0"}
                                          elevation={"1"}
                                          fullWidth={true}
                                          dropDownTextSize={"14"}
                                          dropDownTextColor={"#000"}
                                          activeTextColor={"#000"}
                                          dropdownListMargin={"0"}
                                          onChange={(e) =>
                                            this.onchange_hzkpz(e)
                                          }
                                          dropdownListPadding={"8"}
                                          options={specialityDoctorsOptions}
                                          label={t("Specialist")}
                                          error={this.state.error.speciality}
                                          labelStyle={{
                                            fontSize: "12px",
                                            marginBottom: "6px",
                                            color: "gray",
                                            marginTop: "0px",
                                          }}
                                          isRequired={true}
                                          Url={""}
                                          keyName={""}
                                          valueName={""}
                                          placeholder={""}
                                          inLineStyles={{
                                            border:
                                              this.state.error.speciality &&
                                              "1px solid red",
                                            padding: "5px 4px",
                                            fontSize: "14px",
                                            borderRadius: "4px 0px 0px 4px",
                                            // borderColor: "#9b9b9b",
                                          }}
                                        ></Dropdown>
                                      )}
                                  </div>
                                </div>
                                <div key={"3"} class={"component-77-Y8vGsH"}>
                                  <div
                                    key={"0"}
                                    class={"qdm-dropdown-pqYpRd"}
                                    style={{ marginTop: "12px" }}
                                  >
                                    {purposeOfVisitOptions &&
                                      this.state?.permissionData?.read?.indexOf(
                                        "show_visit_purpost"
                                      ) > -1 && (
                                        <Dropdown
                                          onChange={(e) =>
                                            this.onchange_lknh(e)
                                          }
                                          key={"0"}
                                          elevation={"1"}
                                          fullWidth={true}
                                          dropDownTextSize={"14"}
                                          dropDownTextColor={"#000"}
                                          activeTextColor={"#000"}
                                          dropdownListMargin={"0"}
                                          dropdownListPadding={"8"}
                                          options={purposeOfVisitOptions}
                                          error={
                                            this.state.error.purposeOfVisit
                                          }
                                          label={t("Purpose Of Visit")}
                                          labelStyle={{
                                            fontSize: "12px",
                                            marginBottom: "6px",
                                            color: "gray",
                                            marginTop: "0px",
                                          }}
                                          isRequired={true}
                                          Url={""}
                                          keyName={""}
                                          valueName={""}
                                          placeholder={""}
                                          inLineStyles={{
                                            border:
                                              this.state.error.purposeOfVisit &&
                                              "1px solid red",
                                            padding: "5px 4px",
                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            // borderColor: "#9b9b9b",
                                          }}
                                        ></Dropdown>
                                      )}
                                  </div>
                                </div>
                                <div key={"4"} class={"group-93724-Y8vGsH"}>
                                  <div key={"0"} class={"qdm-button-k6tZF1"}>
                                    <div
                                      key={"0"}
                                      class={"register-visit-Eqv012"}
                                      onClick={(e) =>
                                        this.onclick_0zujx(e, data)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Register Visit
                                    </div>
                                  </div>
                                  {/* <div key={"1"} class={"cancel-button-k6tZF1"}>
                                    <div
                                      key={"0"}
                                      class={"rectangle-47166-HEJyH2"}
                                    ></div>
                                    <div
                                      key={"1"}
                                      class={"cancel-HEJyH2"}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Cancel
                                    </div>
                                  </div> */}
                                </div>
                                <img
                                  key={"5"}
                                  class={"path-93232-Y8vGsH"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/187380252"
                                  }
                                  alt={"path-93232@1x.png"}
                                ></img>
                                <div
                                  key={"6"}
                                  class={"visiting-details-Y8vGsH"}
                                >
                                  Visiting Details
                                </div>
                                <img
                                  key={"7"}
                                  class={"path-93228-Y8vGsH"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/187380230"
                                  }
                                  alt={"path-93228-1@1x.png"}
                                ></img>
                                <div key={"8"} class={"patient-details-Y8vGsH"}>
                                  Patient Details
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}

                  {/* new patient registration */}

                  {Array.isArray(Patient_SearchData?.["result"]) &&
                    // this.state.visit_registration_zopet &&
                    Patient_SearchData?.["result"].length === 0 &&
                    this.state.searchLoader && (
                      <div key={"0"} class={"listitem-Ci3S5d"}>
                        <div
                          key={"0"}
                          class={
                            "patient-registration-updated rectangle-32-Zxtv05"
                          }
                          style={qdmstyles.XNSfv}
                        >
                          <div key={"2"} class={"form-9HxdoZ"}>
                            <div key={"1"} class={"group-93725-Y8vGsH"}>
                              <div key={"0"} class={"component-28-L99nfx"}>
                                <div key={"0"} class={"qdm-textinput-CLsSDU"}>
                                  <TextInput
                                    key={"0"}
                                    type={"text"}
                                    name={t("text")}
                                    variant={"outlined"}
                                    helperTextType={"error"}
                                    label={t("Patient Name")}
                                    error={this.state.error.PatientName}
                                    onChange={(e) => this.onchange_lj4kk(e)}
                                    labelStyle={{
                                      fontSize: "12px",
                                      marginBottom: "6px",
                                      color: "gray",
                                      marginTop: "0px",
                                    }}
                                    autoFocus={false}
                                    autoComplete={"off"}
                                    search={false}
                                    borderColor={"#9b9b9b"}
                                    elevation={"0"}
                                    placeholder={""}
                                    size={"small"}
                                    isRequired={true}
                                    hoverColor={"#000"}
                                    dateIcon={""}
                                    maxLength={"100"}
                                    iconText={""}
                                    minLength={"100"}
                                  ></TextInput>
                                </div>
                              </div>
                              <div key={"1"} class={"component-69-L99nfx"}>
                                <div key={"0"} class={"qdm-textinput-6xHVnp"}>
                                  <TextArea
                                    key={"0"}
                                    type={"text"}
                                    name={t("text")}
                                    variant={"outlined"}
                                    helperTextType={"error"}
                                    onChange={(e) => this.onchange_qvcov(e)}
                                    label={t("Remark")}
                                    labelStyle={{
                                      fontSize: "12px",
                                      marginBottom: "6px",
                                      color: "gray",
                                      marginTop: "0px",
                                    }}
                                    autoFocus={false}
                                    autoComplete={"off"}
                                    search={false}
                                    borderColor={"#9b9b9b"}
                                    elevation={"0"}
                                    placeholder={""}
                                    size={"small"}
                                    maxLength={"500"}
                                    isRequired={false}
                                    hoverColor={"#000"}
                                    dateIcon={""}
                                    maxRows={"5"}
                                    iconText={""}
                                  ></TextArea>
                                </div>
                              </div>
                              <div key={"2"} class={"component-68-L99nfx"}>
                                <div key={"0"} class={"qdm-dropdown-Y59cwJ"}>
                                  {facilityNameOptions && (
                                    <Dropdown
                                      key={"0"}
                                      elevation={"1"}
                                      fullWidth={true}
                                      dropDownTextSize={"14"}
                                      dropDownTextColor={"#000"}
                                      activeTextColor={"#000"}
                                      dropdownListMargin={"0"}
                                      dropdownListPadding={"8"}
                                      options={facilityNameOptions}
                                      error={this.state.error.FacilityName}
                                      onChange={(e) => this.onchange_2njp6(e)}
                                      label={t("Facility Name")}
                                      labelStyle={{
                                        fontSize: "12px",
                                        marginBottom: "6px",
                                        color: "gray",
                                        marginTop: "0px",
                                      }}
                                      isRequired={true}
                                      Url={""}
                                      keyName={""}
                                      valueName={""}
                                      placeholder={""}
                                      // inLineStyles={{
                                      //   padding: "5px 4px",
                                      //   fontSize: "14px",
                                      //   borderRadius: "4px 0px 0px 4px",
                                      //   borderColor: "#9b9b9b",
                                      // }}
                                      inLineStyles={{
                                        border:
                                          this.state.error.FacilityName &&
                                          "1px solid red",
                                        padding: "4px 3px",
                                        fontSize: "14px",
                                      }}
                                    ></Dropdown>
                                  )}
                                  {!facilityNameOptions && (
                                    <Dropdown
                                      key={"0"}
                                      elevation={"1"}
                                      fullWidth={true}
                                      dropDownTextSize={"14"}
                                      dropDownTextColor={"#000"}
                                      activeTextColor={"#000"}
                                      dropdownListMargin={"0"}
                                      dropdownListPadding={"8"}
                                      options={[]}
                                      onChange={(e) => this.onchange_2njp6(e)}
                                      label={t("Facility Name")}
                                      labelStyle={{
                                        fontSize: "12px",
                                        marginBottom: "6px",
                                        color: "gray",
                                        marginTop: "0px",
                                      }}
                                      isRequired={true}
                                      Url={""}
                                      keyName={""}
                                      valueName={""}
                                      placeholder={""}
                                      inLineStyles={{
                                        padding: "5px 4px",
                                        fontSize: "14px",
                                        borderRadius: "4px 0px 0px 4px",
                                        borderColor: "#9b9b9b",
                                      }}
                                    ></Dropdown>
                                  )}
                                </div>
                              </div>
                              <div key={"3"} class={"group-93727-L99nfx"}>
                                <div key={"0"} class={"component-78-7XIOuo"}>
                                  <div key={"0"} class={"qdm-dropdown-w3DTLW"}>
                                    <Dropdown
                                      key={"0"}
                                      elevation={"1"}
                                      fullWidth={true}
                                      dropDownTextSize={"14"}
                                      dropDownTextColor={"#000"}
                                      activeTextColor={"#000"}
                                      dropdownListMargin={"0"}
                                      dropdownListPadding={"8"}
                                      label={t("Gender")}
                                      error={this.state.error.Gender}
                                      onChange={(e) => this.onchange_lj4kk1(e)}
                                      labelStyle={{
                                        fontSize: "12px",
                                        marginBottom: "6px",
                                        color: "gray",
                                        marginTop: "0px",
                                      }}
                                      isRequired={true}
                                      Url={""}
                                      keyName={""}
                                      valueName={""}
                                      placeholdePr={""}
                                      inLineStyles={{
                                        border:
                                          this.state.error.Gender &&
                                          "1px solid red",
                                        padding: "5px 4px",
                                        fontSize: "14px",
                                        // borderColor: "#9b9b9b",
                                      }}
                                      options={[
                                        {
                                          value: "Male",
                                          label: "Male",
                                        },
                                        {
                                          value: "Female",
                                          label: "Female",
                                        },
                                      ]}
                                    ></Dropdown>
                                  </div>
                                </div>
                                <div key={"1"} class={"component-79-7XIOuo"}>
                                  <div key={"0"} class={"qdm-textinput-ba6bAn"}>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        marginBottom: "6px",
                                        color: "gray",
                                        marginTop: "0px",
                                      }}
                                      className={"sc-gsDKAQ gHKREI"}
                                    >
                                      Date of Birth
                                      <span
                                        style={{
                                          marginLeft: "4px",
                                          color: "red",
                                        }}
                                      >
                                        *
                                      </span>
                                    </p>
                                    <input
                                      type={"date"}
                                      style={{
                                        border:
                                          this.state.error.DOB &&
                                          "1px solid red",
                                        height: "32px",
                                        width: "100%",
                                      }}
                                      inLineStyles={{
                                        border:
                                          this.state.error.DOB &&
                                          "1px solid red",
                                      }}
                                      error={this.state.error.DOB}
                                      max={
                                        new Date().toISOString().split("T")?.[0]
                                      }
                                      onKeyDown={(event) => {
                                        event.preventDefault();
                                        return false;
                                      }}
                                      onChange={this.onchange_lj4kk2}
                                    />
                                  </div>
                                </div>
                                <div key={"2"} class={"component-80-7XIOuo"}>
                                  <div key={"0"} class={"qdm-textinput-b5GQFF"}>
                                    <TextInput
                                      key={"0"}
                                      type={"text"}
                                      name={t("text")}
                                      variant={"outlined"}
                                      helperTextType={"error"}
                                      label={t("Age")}
                                      labelStyle={{
                                        fontSize: "12px",
                                        marginBottom: "6px",
                                        color: "gray",
                                        marginTop: "0px",
                                      }}
                                      autoFocus={false}
                                      autoComplete={"off"}
                                      search={false}
                                      borderColor={"#9b9b9b"}
                                      elevation={"0"}
                                      placeholder={""}
                                      size={"small"}
                                      disabled={true}
                                      isRequired={true}
                                      value={this.state?.age}
                                      hoverColor={"#000"}
                                      dateIcon={""}
                                      maxLength={"100"}
                                      iconText={""}
                                      minLength={"100"}
                                    ></TextInput>
                                  </div>
                                </div>
                              </div>
                              <div key={"4"} class={"component-67-L99nfx"}>
                                <div key={"0"} class={"qdm-textinput-IE6RqY"}>
                                  <TextInput
                                    key={"0"}
                                    type={"text"}
                                    name={t("text")}
                                    variant={"outlined"}
                                    helperTextType={"error"}
                                    label={t("EMP No")}
                                    error={this.state.error.EmpNo}
                                    onChange={(e) => this.onchange_qlm9w(e)}
                                    labelStyle={{
                                      fontSize: "12px",
                                      marginBottom: "6px",
                                      color: "gray",
                                      marginTop: "0px",
                                    }}
                                    autoFocus={false}
                                    autoComplete={"off"}
                                    search={false}
                                    borderColor={"#9b9b9b"}
                                    elevation={"0"}
                                    placeholder={""}
                                    size={"small"}
                                    isRequired={true}
                                    hoverColor={"#000"}
                                    dateIcon={""}
                                    maxLength={"100"}
                                    iconText={""}
                                    minLength={"100"}
                                    onKeyDown={(e) => {
                                      const charCode = e.which
                                        ? e.which
                                        : e.keyCode;
                                      if (
                                        charCode > 31 &&
                                        (charCode < 48 || charCode > 57)
                                      ) {
                                        e.preventDefault();
                                        return;
                                      }
                                    }}
                                  ></TextInput>
                                </div>
                              </div>
                              <div key={"5"} class={"component-93700-L99nfx"}>
                                <div key={"0"} class={"qdm-textinput-8Wz9jn"}>
                                  <TextInput
                                    key={"0"}
                                    type={"text"}
                                    name={t("text")}
                                    variant={"outlined"}
                                    helperTextType={"error"}
                                    label={t("Mobile Number")}
                                    error={this.state.error.MobileNumber}
                                    onChange={(e) => this.onchange_ukwmz(e)}
                                    labelStyle={{
                                      fontSize: "12px",
                                      marginBottom: "6px",
                                      color: "gray",
                                      marginTop: "0px",
                                    }}
                                    autoFocus={false}
                                    autoComplete={"off"}
                                    search={false}
                                    borderColor={"#9b9b9b"}
                                    elevation={"0"}
                                    placeholder={""}
                                    size={"small"}
                                    isRequired={true}
                                    hoverColor={"#000"}
                                    dateIcon={""}
                                    maxLength={10}
                                    iconText={""}
                                    minLength={10}
                                    onKeyDown={(e) => {
                                      const charCode = e.which
                                        ? e.which
                                        : e.keyCode;
                                      if (
                                        charCode > 31 &&
                                        (charCode < 48 || charCode > 57)
                                      ) {
                                        e.preventDefault();
                                        return;
                                      }
                                    }}
                                  ></TextInput>
                                </div>
                              </div>
                              <div key={"6"} class={"component-93700-NWHnxr"}>
                                <div key={"0"} class={"qdm-textinput-UtUZ2W"}>
                                  <TextInput
                                    key={"0"}
                                    type={"text"}
                                    name={t("text")}
                                    variant={"outlined"}
                                    helperTextType={"error"}
                                    label={t("ID Card No")}
                                    error={this.state.error.IdCard}
                                    onChange={(e) => this.onchange_ec1wv(e)}
                                    labelStyle={{
                                      fontSize: "12px",
                                      marginBottom: "6px",
                                      color: "gray",
                                      marginTop: "0px",
                                    }}
                                    autoFocus={false}
                                    autoComplete={"off"}
                                    search={false}
                                    borderColor={"#9b9b9b"}
                                    elevation={"0"}
                                    placeholder={"Enter your ID number"}
                                    size={"small"}
                                    isRequired={true}
                                    hoverColor={"#000"}
                                    dateIcon={""}
                                    maxLength={
                                      this.state.patient_registration_eqt7z
                                        ?.value === "Passport"
                                        ? "9"
                                        : "12"
                                    }
                                    iconText={""}
                                    minLength={"100"}
                                    inLineStyles={{
                                      borderRadius: "0px 4px 0px 0px",
                                    }}
                                  ></TextInput>
                                </div>
                                <div key={"1"} class={"qdm-dropdown-UtUZ2W"}>
                                  <Dropdown
                                    key={"0"}
                                    elevation={"1"}
                                    fullWidth={true}
                                    dropDownTextSize={"14"}
                                    onChange={(e) => this.onchange_eqt7z(e)}
                                    dropDownTextColor={"#000"}
                                    activeTextColor={"#000"}
                                    dropdownListMargin={"0"}
                                    dropdownListPadding={"8"}
                                    options={[
                                      {
                                        value: "Passport",
                                        label: "Passport",
                                      },
                                      {
                                        value: "NRIC",
                                        label: "NRIC",
                                      },
                                    ]}
                                    label={t("ID Type")}
                                    labelStyle={{
                                      fontSize: "12px",
                                      marginBottom: "6px",
                                      color: "gray",
                                      marginTop: "0px",
                                    }}
                                    isRequired={true}
                                    Url={""}
                                    keyName={""}
                                    valueName={""}
                                    name={t("ID Type")}
                                    error={this.state.error.IdType}
                                    inLineStyles={{
                                      border:
                                        this.state.error.IdType &&
                                        "1px solid red",
                                      padding: "5px 4px",
                                      fontSize: "14px",
                                      borderRadius: "4px 0px 0px 4px",
                                      // borderColor: "#9b9b9b",
                                    }}
                                  ></Dropdown>
                                </div>
                              </div>
                            </div>
                            <div key={"2"} class={"component-76-Y8vGsH"}>
                              <div key={"0"} class={"qdm-dropdown-bFecFd"}>
                                {specialityDoctorsOptions &&
                                  this.state?.permissionData?.read?.indexOf(
                                    "show_doctor_details"
                                  ) > -1 && (
                                    <Dropdown
                                      key={"0"}
                                      elevation={"1"}
                                      fullWidth={true}
                                      dropDownTextSize={"14"}
                                      dropDownTextColor={"#000"}
                                      activeTextColor={"#000"}
                                      dropdownListMargin={"0"}
                                      error={this.state.error.speciality}
                                      onChange={(e) => this.onchange_hzkpz(e)}
                                      dropdownListPadding={"8"}
                                      options={specialityDoctorsOptions}
                                      label={t("Specialist")}
                                      labelStyle={{
                                        fontSize: "12px",
                                        marginBottom: "6px",
                                        color: "gray",
                                        marginTop: "0px",
                                      }}
                                      isRequired={true}
                                      Url={""}
                                      keyName={""}
                                      valueName={""}
                                      placeholder={""}
                                      inLineStyles={{
                                        border:
                                          this.state.error.speciality &&
                                          "1px solid red",
                                        padding: "5px 4px",
                                        fontSize: "14px",
                                        borderRadius: "4px",
                                        // borderColor: "#9b9b9b",
                                      }}
                                    ></Dropdown>
                                  )}
                              </div>
                            </div>
                            <div key={"3"} class={"component-77-Y8vGsH"}>
                              <div
                                key={"0"}
                                class={"qdm-dropdown-pqYpRd"}
                                style={{ marginTop: "12px" }}
                              >
                                {purposeOfVisitOptions &&
                                  this.state?.permissionData?.read?.indexOf(
                                    "show_visit_purpost"
                                  ) > -1 && (
                                    <Dropdown
                                      onChange={(e) => this.onchange_lknh(e)}
                                      key={"0"}
                                      elevation={"1"}
                                      fullWidth={true}
                                      dropDownTextSize={"14"}
                                      dropDownTextColor={"#000"}
                                      activeTextColor={"#000"}
                                      dropdownListMargin={"0"}
                                      dropdownListPadding={"8"}
                                      error={this.state.error.purposeOfVisit}
                                      options={purposeOfVisitOptions}
                                      label={t("Purpose Of Visit")}
                                      labelStyle={{
                                        fontSize: "12px",
                                        marginBottom: "6px",
                                        color: "gray",
                                        marginTop: "0px",
                                      }}
                                      isRequired={true}
                                      Url={""}
                                      keyName={""}
                                      valueName={""}
                                      placeholder={""}
                                      inLineStyles={{
                                        border:
                                          this.state.error.purposeOfVisit &&
                                          "1px solid red",
                                        padding: "5px 4px",
                                        fontSize: "14px",
                                        borderRadius: "4px",
                                        // border: "1px solid #9b9b9b",
                                      }}
                                    ></Dropdown>
                                  )}
                              </div>
                            </div>
                            <div key={"4"} class={"group-93724-Y8vGsH"}>
                              <div key={"0"} class={"qdm-button-k6tZF1"}>
                                <div
                                  key={"0"}
                                  onClick={(e) =>
                                    this.onclick_0zujx(e, data, true)
                                  }
                                  class={"register-visit-Eqv012"}
                                  style={{ cursor: "pointer" }}
                                >
                                  Register Visit
                                </div>
                              </div>
                            </div>
                            <img
                              key={"5"}
                              class={"path-93232-Y8vGsH"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/187380252"
                              }
                              alt={"path-93232@1x.png"}
                            ></img>
                            <div key={"6"} class={"visiting-details-Y8vGsH"}>
                              Visiting Details
                            </div>
                            <img
                              key={"7"}
                              class={"path-93228-Y8vGsH"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/187380230"
                              }
                              alt={"path-93228-1@1x.png"}
                            ></img>
                            <div key={"8"} class={"patient-details-Y8vGsH"}>
                              New Patient Registration
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {/* {data?.result} */}
                </div>
                <div key={"1"} class={"group-93721-JQaIUO"}>
                  <div key={"0"} class={"rectangle-47180-sXrDpa"}></div>
                  <div key={"1"} class={"login-avatar-sXrDpa"}>
                    <Logout />
                  </div>
                  <div key={"2"} class={"visit-registration-sXrDpa"}>
                    Visit Registration
                  </div>
                </div>
                <div key={"2"} class={"group-93720-JQaIUO"}>
                  <div key={"0"} class={"rectangle-47173-Io4y1R"}></div>
                  <div key={"1"} class={"group-93719-Io4y1R"}>
                    <div key={"0"} class={"logo-01bKn4"}>
                      <img
                        key={"0"}
                        class={"x51-client_profile_subscription-10-inMWm9"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739820"
                        }
                        alt={"5-1-client-profile-subscription---10@1x.png"}
                      ></img>
                      <div key={"1"} class={"global-doctors-inMWm9"}>
                        <span key={"0"} class={"span0-PYDfvh"}>
                          Global
                        </span>
                        <span key={"1"} class={"span1-PYDfvh"}>
                          Doctors
                        </span>
                      </div>
                    </div>
                    <div key={"1"} class={"version-01bKn4"}>
                      <div key={"0"} class={"v1001-mjI7WH"}>
                        {"v" + localStorage?.version ?? ""}
                      </div>
                    </div>
                    <div key={"2"} class={"login-details-01bKn4"}>
                      <div key={"0"} class={"hello-CP9rEC"}>
                        Hello{" "}
                      </div>
                      <div key={"1"} class={"mr-rakin-CP9rEC"}>
                        {sessionStorage.getItem("name")}
                      </div>
                      <div key={"2"} class={"sathishrakinainqacom-CP9rEC"}>
                        {sessionStorage.getItem("username")}
                      </div>
                      <img
                        key={"3"}
                        class={"line-9-CP9rEC"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744502"
                        }
                        alt={"line-9@1x.png"}
                      ></img>
                    </div>
                    <div
                      key={"3"}
                      class={"reports-01bKn4"}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/reports");
                        }}
                        class={"reports-5zPeTW roboto-medium-dove-gray-14px"}
                      >
                        Reports
                      </div>
                      <img
                        key={"1"}
                        class={"download-7-5zPeTW"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744506"
                        }
                        alt={"download--7-@1x.png"}
                      ></img>
                    </div>
                    <div
                      key={"5"}
                      class={"master-01bKn4"}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/specialist_doctor");
                        }}
                        class={"master-HNJFiO roboto-medium-dove-gray-14px"}
                      >
                        Master
                      </div>
                      <img
                        key={"1"}
                        class={"material-master-data-HNJFiO"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744545"
                        }
                        alt={
                          "material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"
                        }
                      ></img>
                    </div>
                    <div key={"6"} class={"visit-regn-01bKn4"}>
                      <div key={"0"} class={"rectangle-47179-khzOsH"}></div>
                      <div key={"1"} class={"visit-registration-khzOsH"}>
                        Visit Registration
                      </div>
                      <img
                        key={"2"}
                        class={"download-6-khzOsH"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744562"
                        }
                        alt={"download--6-@1x.png"}
                      ></img>
                    </div>
                  </div>
                </div>
                <div
                  key={"3"}
                  class={"group-93707-JQaIUO"}
                  style={qdmstyles.BZhQD}
                >
                  <div
                    key={"0"}
                    class={"qdm-button-E62kL9"}
                    style={{ top: "0px", cursor: "pointer" }}
                  >
                    <div
                      key={"0"}
                      onClick={(e) => this.onclick_vgpos(e, data)}
                      class={"search-6vogkz"}
                      style={qdmstyles.vgpoS}
                    >
                      Search
                    </div>
                  </div>
                  <div
                    key={"1"}
                    class={"qdm-textinput-E62kL9"}
                    style={qdmstyles.kWle}
                  >
                    {/* <Dropdown
                      key={"0"}
                      onChange={(e) => this.onchange_hzkpznj(e)}
                      elevation={"1"}
                      fullWidth={true}
                      labelStyle={{
                        fontSize: "12px",
                        marginBottom: "3px",
                        color: "gray",
                      }}
                      dropDownTextSize={"14"}
                      dropDownTextColor={"#000"}
                      activeTextColor={"#000"}
                      dropdownListMargin={"0"}
                      dropdownListPadding={"8"}
                      options={[
                        { label: "Patient Id", value: "Patient_ID_Number" },
                        { label: "Mobile No", value: "Mobile_Number" },
                        { label: "Emp No", value: "Emp_Number" },
                        { label: "MRN", value: "MRN" },
                      ]}
                      label={t("")}
                      isRequired={true}
                      Url={""}
                      keyName={"name"}
                      valueName={"id"}
                      placeholder={"Search By"}
                      inLineStyles={qdmstyles.hzKpZ}
                    ></Dropdown> */}
                    <TextInput
                      key={"0"}
                      onChange={(e) => this.onchange_zopet(e)}
                      type={"text"}
                      name={t("Patient Search")}
                      variant={"none"}
                      helperTextType={"error"}
                      label={t("")}
                      autoFocus={false}
                      autoComplete={"off"}
                      search={false}
                      borderColor={"#9b9b9b"}
                      elevation={"0"}
                      placeholder={
                        "Enter Patient Name or Employee Number or MRN or ID Number or Mobile Number"
                      }
                      size={"medium"}
                      isRequired={false}
                      hoverColor={"#000"}
                      dateIcon={""}
                      maxLength={"100"}
                      iconText={""}
                      minLength={"100"}
                      style={qdmstyles.zoPet}
                    ></TextInput>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              open={
                this.state?.visit_registration_0zujx_osqne &&
                !this.state.patient_registration_qdxhq_9sv6l
                //   Boolean(
                //   this.state?.visit_registration_0zujx_osqne === true &&
                //     !this.state.patient_registration_qdxhq_9sv6l
                // )
              }
              onClose={() => {
                // document.body.style.overflow = '';
                {
                  window.location.reload();
                  this.setState({
                    visit_registration_0zujx_osqne: null,
                  });
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "380px",
                  height: "380px",
                  alignItems: "center",
                }}
              >
                {this.state.qrCode ? (
                  <>
                    <div>Scan the QR Code with a smart glass</div>
                    <div style={{ marginTop: 10 }}>
                      <img
                        src={`data:image/png;base64, ${this.state.qrCode}`}
                        alt="QR Code"
                      />
                    </div>
                  </>
                ) : (
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "blue" }}
                    spin
                  />
                )}
              </div>
            </Modal>
            <Modal
              open={this.state.patient_registration_qdxhq_9sv6l}
              onClose={() => {
                this.setState({
                  patient_registration_qdxhq_9sv6l: null,
                });
              }}
            >
              <Success
                state={this.state.SuccessState}
                Facility_Name={this.state.Facility_Name}
              />
            </Modal>
            <div
              id="myModal"
              class="modal"
              style={{
                display: this.state.facilityModal
                  ? "block"
                  : "none" /* Hidden by default */,
                position: "fixed" /* Stay in place */,
                zIndex: "1" /* Sit on top */,
                paddingTop: "100px" /* Location of the box */,
                left: "0",
                top: "0",
                width: "100%" /* Full width */,
                height: "100%" /* Full height */,
                overflow: "auto" /* Enable scroll if needed */,
                // backgroundColor: "rgb(0,0,0)" /* Fallback color */,
                backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
              }}
            >
              <div
                class="modal-content"
                style={{
                  backgroundColor: "#fff",
                  margin: "auto",
                  padding: "20px",
                  borderRadius: "5px",
                  minHeight: "210px",
                  border: "1px solid #888",
                  width: "30%",
                  display: "grid",
                  alignItems: "center",
                }}
              >
                <div>
                  <h3 style={{ fontSize: "18px", marginBottom: "32px" }}>
                    Select the facility location
                  </h3>
                  <Dropdown
                    key={"0"}
                    elevation={"1"}
                    fullWidth={true}
                    dropDownTextSize={"14"}
                    dropDownTextColor={"#000"}
                    activeTextColor={"#000"}
                    dropdownListMargin={"0"}
                    dropdownListPadding={"8"}
                    options={facilityNameOptions}
                    onChange={(e) => {
                      console.log(e);
                      this.setState({
                        facility: e,
                      });
                    }}
                    label={t("Facility Name")}
                    labelStyle={{
                      fontSize: "12px",
                      marginBottom: "6px",
                      color: "gray",
                      marginTop: "0px",
                    }}
                    isRequired={true}
                    Url={""}
                    keyName={""}
                    valueName={""}
                    placeholder={""}
                    inLineStyles={{
                      padding: "5px 4px",
                      fontSize: "14px",
                      borderColor: "#9b9b9b",
                    }}
                  ></Dropdown>
                  <Button
                    type="primary"
                    style={{
                      margin: "32px 0px 16px 0px",
                      float: "right",
                      backgroundColor: this.state.facility
                        ? "rgb(0, 99, 231)"
                        : "lightGray",
                      borderRadius: "5px",
                    }}
                    disabled={this.state.facility ? false : true}
                    onClick={this.handleSubmitFacility}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Patient_SearchData:
      state?.Visit_RegistrationPatient_Search_vgpoSReducer?.Patient_Search_vgpoS
        ?.data,
    Encounter_InsertData:
      state?.Visit_RegistrationEncounter_Insert_0ZUjxReducer
        ?.Encounter_Insert_0ZUjx?.data,
    facilityNameOptions:
      state.All_Dropdowns_Reducer?.facilityName?.data?.result,
    specialityDoctorsOptions:
      state.All_Dropdowns_Reducer?.specialityDoctors?.data?.result,
    purposeOfVisitOptions:
      state.All_Dropdowns_Reducer?.purposeOfVisit?.data?.result,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(translate()(VisitRegistration))));
