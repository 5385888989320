import React from "react";
import { actions } from "realwarebinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import "./index.css";
import { languageOptions } from "../../utils";
import { Logout } from "../../components";
import { LoggerContext } from "../../contexts";

import * as ALL from "../../components";

import { div } from "qdm-component-library";

class SpecialistDoctor extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      specialist_doctor_wlncn: null,
      specialist_doctor_ipfez: null,
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onclick_ipfez = async (event, data) => {
    const payload0 = { history: this.props.history.location.state, data: data };
    let data0 = await this.props.Specialist_DoctorPractitioner_SoftDelete_IPfEZ(
      payload0
    );

    ///refresh enabled
    if (data0?.payload?.Code === 200) {
      window.location.reload("");
    }
  };
  componentDidMount() {
    /// sample localstorage translation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }

    const payload = { history: this.props.history.location.state };
    this.props.Specialist_DoctorPractitioner_Specialist_Fullread_Updated_wLNCN(
      payload
    );
  }

  shouldComponentUpdate(props, state) {
    if (
      this.props?.Practitioner_SoftDeleteData?.loading !==
      props?.Practitioner_SoftDeleteData?.loading
    ) {
      if (
        !props?.Practitioner_SoftDeleteData?.loading &&
        props?.Practitioner_SoftDeleteData.error
      ) {
      } else if (
        !props?.Practitioner_SoftDeleteData?.loading &&
        !props?.Practitioner_SoftDeleteData.error
      ) {
      }
    }

    return true;
  }

  render() {
    const {
      SpecialtyData,
      Practitioner_Specialist_Fullread_UpdatedData,
      Practitioner_SoftDeleteData,
      data,
    } = this.props;
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <div key={"0"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"master-specialist-doctor"}
              ></input>
              <div key={"1"} class={"master-specialist-doctor screen"}>
                <img
                  key={"0"}
                  class={"line-8-sKqQC0"}
                  src={
                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770470"
                  }
                  alt={"line-8@1x.png"}
                ></img>
                <div
                  key={"1"}
                  onClick={() => {
                    this.props.history.push("/onsite_doctor");
                  }}
                  class={"onsite-doctor-medical-assistant-sKqQC0"}
                  style={{ cursor: "pointer" }}
                >
                  Onsite Doctor/Medical Assistant
                </div>
                <div key={"2"} class={"specialist-doctors-sKqQC0"}>
                  <div key={"0"} class={"specialist-doctor-Tc9Qm5"}>
                    Specialist Doctor
                  </div>
                  <img
                    key={"1"}
                    class={"rectangle-43868-Tc9Qm5"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770484"
                    }
                    alt={"rectangle-43868@1x.png"}
                  ></img>
                </div>
                <div
                  key={"3"}
                  onClick={() => {
                    this.props.history.push("/patient_facility");
                  }}
                  class={"patient-facility-sKqQC0"}
                  style={{ cursor: "pointer" }}
                >
                  Patient Facility
                </div>
                <div key={"4"} class={"qdm-button-sKqQC0"}>
                  <div
                    key={"0"}
                    onClick={() => {
                      this.props.history.push("/add_specialist_doctor");
                    }}
                    class={"add-new-NwfBd7"}
                    style={{ cursor: "pointer" }}
                  >
                    Add New
                  </div>
                </div>
                <div key={"5"} class={"group-93711-sKqQC0"}>
                  <div key={"0"} class={"rectangle-47173-9HtIAx"}></div>
                  <div key={"1"} class={"group-93715-9HtIAx"}>
                    <div key={"0"} class={"logo-cEKuIH"}>
                      <img
                        key={"0"}
                        class={"x51-client_profile_subscription-10-gjZCAb"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739820"
                        }
                        alt={"5-1-client-profile-subscription---10@1x.png"}
                      ></img>
                      <div key={"1"} class={"global-doctors-gjZCAb"}>
                        <span key={"0"} class={"span0-1FAQAl"}>
                          Global
                        </span>
                        <span key={"1"} class={"span1-1FAQAl"}>
                          Doctors
                        </span>
                      </div>
                    </div>
                    <div key={"1"} class={"login-details-cEKuIH"}>
                      <div key={"0"} class={"hello-kBWzxf"}>
                        Hello{" "}
                      </div>
                      <div key={"1"} class={"mr-rakin-kBWzxf"}>
                        {sessionStorage.getItem("name")}
                      </div>
                      <div key={"2"} class={"sathishrakinainqacom-kBWzxf"}>
                        {sessionStorage.getItem("username")}
                      </div>
                      <img
                        key={"3"}
                        class={"line-9-kBWzxf"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744502"
                        }
                        alt={"line-9@1x.png"}
                      ></img>
                    </div>
                    <div key={"2"} class={"reports-cEKuIH"}>
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/reports");
                        }}
                        class={"reports-9UMiQm roboto-medium-dove-gray-14px"}
                        style={{ cursor: "pointer" }}
                      >
                        Reports
                      </div>
                      <img
                        key={"1"}
                        class={"download-7-9UMiQm"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744506"
                        }
                        alt={"download--7-@1x.png"}
                      ></img>
                    </div>
                    <div key={"4"} class={"rectangle-47179-cEKuIH"}></div>
                    <div key={"5"} class={"master-cEKuIH"}>
                      <div key={"0"} class={"master-dxvZzg"}>
                        Master
                      </div>
                      <img
                        key={"1"}
                        class={"material-master-data-dxvZzg"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744545"
                        }
                        alt={
                          "material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"
                        }
                      ></img>
                    </div>
                    <div key={"6"} class={"group-93714-cEKuIH"}>
                      <div
                        key={"0"}
                        class={
                          "visit-registration-JxDQui roboto-medium-dove-gray-14px"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.props.history.push("/visit_registration");
                        }}
                      >
                        Visit Registration
                      </div>
                      <img
                        key={"1"}
                        class={"download-6-JxDQui"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744562"
                        }
                        alt={"download--6-@1x.png"}
                      ></img>
                    </div>
                  </div>
                </div>
                <div key={"6"} class={"group-93712-sKqQC0"}>
                  <div key={"0"} class={"rectangle-47180-o2SaxQ"}></div>
                  <div key={"1"} class={"login-avatar-o2SaxQ"}>
                    <Logout />
                  </div>
                  <div key={"2"} class={"master-o2SaxQ"}>
                    Master
                  </div>
                </div>
                <div key={"7"} class={"version-sKqQC0"}>
                  <div key={"0"} class={"v1001-dhua6L"}>
                    {"v" + localStorage?.version ?? ""}
                  </div>
                </div>
                <div
                  key={"8"}
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: 10,
                    background: "#fff",
                    height:"80vh",
                    overflow:"scroll"
                  }}
                  class={"group-93713-sKqQC0"}
                >
                  <table style={{ width: "100%" }}>
                    <tr style={{ borderBottom: '1px solid #e0e0e0' }}>
                      <th style={{ padding: "10px 10px 10px 20px"  }}>Name  </th>
                      <th>Speciality </th>
                      <th>Location</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Mobile</th>
                      <th>Actions</th>
                    </tr>
                    {Array.isArray(
                      Practitioner_Specialist_Fullread_UpdatedData?.["result"]
                    ) &&
                      Practitioner_Specialist_Fullread_UpdatedData?.[
                        "result"
                      ]?.map((data, index) => {
                        return (<tr>
                          <td style={{ padding: "10px 10px 10px 20px" }}> {data?.Practitioner_Name}</td>
                          <td> {data?.Specialty_ID}</td>
                          <td>{data?.Location}</td>
                          <td>{data?.Practitioner_Mail_ID}</td>
                          <td style={{width:"220px"}}> {data?.Address}</td>
                          <td>{data?.Phone_Number}</td>
                          <td>
                            <div key={"0"}>
                             
                              <img
                                key={"1"}
                                style={{ width: "16px", marginRight: 10 }}
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: "/edit_specialist_doctor",
                                    state: {
                                      Specialist_Doctor__key:
                                        typeof index === "undefined"
                                          ? Array.isArray(
                                            this.props
                                              .Practitioner_Specialist_Fullread_UpdatedData
                                              ?.data
                                          )
                                            ? this.props
                                              .Practitioner_Specialist_Fullread_UpdatedData
                                              ?.data[0]._key
                                            : this.props
                                              .Practitioner_Specialist_Fullread_UpdatedData
                                              ?.data._key
                                          : data._key,
                                      Specialist_Doctor__id:
                                        typeof index === "undefined"
                                          ? Array.isArray(
                                            this.props
                                              .Practitioner_Specialist_Fullread_UpdatedData
                                              ?.data
                                          )
                                            ? this.props
                                              .Practitioner_Specialist_Fullread_UpdatedData
                                              ?.data[0]._id
                                            : this.props
                                              .Practitioner_Specialist_Fullread_UpdatedData
                                              ?.data._id
                                          : data._id,
                                    },
                                  });
                                }}
                                src={
                                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770456"
                                }
                                alt={"icon-material-edit@1x.png"}
                              ></img>
                               <img
                                key={"0"}
                                onClick={(e) => this.onclick_ipfez(e, data)}
                                style={{ width: "12px",marginLeft:"10px" }}
                                src={
                                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183770449"
                                }
                                alt={"icon-material-delete@1x.png"}
                              ></img>
                            </div>
                          </td>
                        </tr>)
                      })}

                    {data?.result}
                  </table>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}

const mapStateToProps = (state) => ({
  Practitioner_Specialist_Fullread_UpdatedData:
    state
      ?.Specialist_DoctorPractitioner_Specialist_Fullread_Updated_wLNCNReducer
      ?.Practitioner_Specialist_Fullread_Updated_wLNCN?.data,
  Practitioner_SoftDeleteData:
    state?.Specialist_DoctorPractitioner_SoftDelete_IPfEZReducer
      ?.Practitioner_SoftDelete_IPfEZ?.data,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(SpecialistDoctor)));
