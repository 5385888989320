import React from "react";
import { actions } from "realwarebinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal, Radio } from "qdm-component-library";
import "./index.css";
import { languageOptions } from "../../utils";
import jwt_decode from "jwt-decode";
import { withAllContexts } from "../../HOCs";
import axios from "axios";
import { LoggerContext } from "../../contexts";

import * as ALL from "../../components";

import { TextInput } from "qdm-component-library";

class Login extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: "",
      password: "",
      remember_me: false,
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    /// sample localstorage translation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    if (sessionStorage.getItem("remember_me") === "true") {
      this.setState({
        ...this.state,
        email: sessionStorage.getItem("username"),
        password: sessionStorage.getItem("password"),
        remember_me: true,
      });
    }
    // if (sessionStorage.username) {
    //   this.props.history.push("/visit_registration");
    // }
  }

  setCred = (name, v) => {
    this.setState({ [name]: v });
  };

  handleForgotPassword = async () => {
    try {
      // let { username = "" } = this.state.email;

      if (!this.state.email) {
        this.props.alert.setSnack({
          open: true,
          msg: `Please enter the username!`,
          severity: "error",
          setTimeout: 1000,
        });
        return;
      }

      let _ = axios
        .post(
          `https://apinifi.dev.ainqaplatform.in/api/v1/realWearForgotPassword`,
          btoa(
            JSON.stringify({
              username: this.state.email,
            })
          )
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      // await fetch(
      //   `https://apinifi.dev.ainqaplatform.in/api/v1/forgotOrgUserPassword`,
      //   {
      //     body:
      //     ),
      //   }
      // );
      // _ = await _.json();
      console.log(_);
    } catch (error) {
      console.log(error);
    }
  };

  casbianRouteImplementation = async (allPermissionList) => {
    let pageJson = allPermissionList?.repo_mapping;
    sessionStorage.setItem("permissionData", btoa(JSON.stringify(pageJson)));
    let Arr = [];
    let Pages = Object.keys(pageJson)?.filter(
      (v) =>
        v && (pageJson[v]?.permission.read || pageJson[v]?.permission.write)
    );
    let data = Pages?.filter((v) => {
      Arr.push(pageJson[v]?.routes ?? "");
    });
    const permission = {
      admin: {
        access: Arr,
      },
    };
    localStorage.setItem("role_name", "admin");
    localStorage.setItem("role_data", btoa(JSON.stringify(permission)));
  };

  login = async () => {
    let payload = {
      email: this.state?.email,
      password: this.state?.password,
    };
    let data = await this.props.login(payload);
    if (data?.payload?.Code === 200 && data?.payload?.access_token) {
      var decoded = (decoded = jwt_decode(data?.payload?.access_token));
      sessionStorage.setItem("name", decoded?.name?.split("@")[0]);
      sessionStorage.setItem("token", data?.payload?.access_token);
      sessionStorage.setItem("username", this.state?.email);
      if (this.state?.remember_me) {
        sessionStorage.setItem("remember_me", true);
        sessionStorage.setItem("password", this.state?.password);
      } else {
        sessionStorage.removeItem("remember_me");
        sessionStorage.removeItem("password");
      }
      let data1 = await this.props.allPermissions();
      if (data1?.payload?.Code) {
        let person_data = data1?.payload?.result[0]?.IDM_Person;
        sessionStorage.setItem("role_id", person_data?.roleid);
        let allPermissionList = await this.props.screenLevelPermission();
        if (
          allPermissionList?.payload?.Code &&
          allPermissionList?.payload?.result[0]
        ) {
          allPermissionList =
            allPermissionList?.payload?.result[0]?.IDM_PermissionRoleMapping
              ?.permission?.permission[0];
          this.casbianRouteImplementation(allPermissionList);
          this.props.history.push("/visit_registration");
        }
      }
    } else {
      this.props.alert.setSnack({
        open: true,
        msg: `You don’t have enough permission to access the content`,
        severity: "error",
        setTimeout: 1000,
      });
    }
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <div key={"0"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"login"}
              ></input>
              <div key={"1"} class={"login screen"}>
                <img
                  key={"0"}
                  class={"path-93227-b8DrbD"}
                  src={
                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739841"
                  }
                  alt={"path-93227@1x.png"}
                ></img>
                <div key={"1"} class={"group-93715-b8DrbD"}>
                  <div key={"0"} class={"welcome-bgeoTh"}>
                    Welcome
                  </div>
                  <div key={"1"} class={"qdm-textinput-bgeoTh"}>
                    <TextInput
                      key={"0"}
                      type={"text"}
                      name={"text"}
                      variant={"outlined"}
                      helperTextType={"error"}
                      label={""}
                      autoFocus={false}
                      autoComplete={"off"}
                      search={false}
                      borderColor={"#9b9b9b"}
                      elevation={"0"}
                      placeholder={"Username"}
                      size={"medium"}
                      isRequired={true}
                      hoverColor={"#000"}
                      dateIcon={""}
                      maxLength={"100"}
                      iconText={""}
                      minLength={""}
                      value={this.state.email}
                      onChange={(e) => this.setCred("email", e.target.value)}
                    ></TextInput>
                  </div>
                  <div key={"2"} class={"qdm-textinput-Cxvxcm"}>
                    <TextInput
                      key={"0"}
                      type={"text"}
                      name={"text"}
                      variant={"outlined"}
                      helperTextType={"error"}
                      label={""}
                      autoFocus={false}
                      autoComplete={"off"}
                      search={false}
                      borderColor={"#9b9b9b"}
                      elevation={"0"}
                      placeholder={"Password"}
                      size={"medium"}
                      isRequired={true}
                      hoverColor={"#000"}
                      dateIcon={""}
                      maxLength={"100"}
                      iconText={""}
                      minLength={"100"}
                      className="passwordField"
                      value={this.state.password}
                      onChange={(e) => this.setCred("password", e.target.value)}
                    ></TextInput>
                  </div>
                  <div key={"3"} class={"qdm-button-bgeoTh"}>
                    <div
                      key={"0"}
                      onClick={this.login}
                      class={"sign-in-qwfgee"}
                      style={{ cursor: "pointer" }}
                    >
                      Sign In
                    </div>
                  </div>
                  <div
                    key={"4"}
                    style={{ cursor: "pointer" }}
                    class={"forgot-password-bgeoTh"}
                    onClick={this.handleForgotPassword}
                  >
                    Forgot Password?
                  </div>
                  <div key={"5"} class={"sign-in-to-your-acco-bgeoTh"}>
                    Sign-in to your account using your login username and
                    password
                  </div>
                  <div key={"6"} class={"group-93714-bgeoTh"}>
                    <div key={"0"} class={"remember-me-xa4FNl"}>
                      Remember me
                    </div>
                    <input
                      type={"checkbox"}
                      checked={this.state.remember_me}
                      onChange={() => {
                        this.setCred("remember_me", !this.state?.remember_me);
                      }}
                    />
                  </div>
                </div>
                <div key={"2"} class={"group-93723-b8DrbD"}>
                  <img
                    key={"0"}
                    class={"undraw_medicine_b1ol-qr1pd2"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739850"
                    }
                    alt={"undraw-medicine-b1ol@1x.png"}
                  ></img>
                </div>
                <div key={"3"} class={"group-93713-b8DrbD"}>
                  <img
                    key={"0"}
                    class={"x51-client_profile_subscription-10-h0zH23"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739820"
                    }
                    alt={"5-1-client-profile-subscription---10@1x.png"}
                  ></img>
                  <div key={"1"} class={"global-doctors-h0zH23"}>
                    <span key={"0"} class={"span0-3GZPF5"}>
                      Global
                    </span>
                    <span key={"1"} class={"span1-3GZPF5"}>
                      Doctors
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(withRouter(translate()(Login))));
