import React from "react";
import { actions } from "realwarebinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import "./index.css";
import { languageOptions, fieldValidator } from "../../utils";
import { LoggerContext } from "../../contexts";
import { withAllContexts } from "../../HOCs";
import { Success } from "../../components";
import { Logout } from "../../components";
import * as ALL from "../../components";

import { TextInput, Dropdown } from "qdm-component-library";
import { checkWithCasbin } from "../../utils";

class PatientRegistration extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      patient_registration_lj4kk: null,
      patient_registration_ec1wv: null,
      patient_registration_qlm9w: null,
      patient_registration_ukwmz: null,
      patient_registration_2njp6: null,
      patient_registration_qvcov: null,
      patient_registration_eqt7z: null,
      patient_registration_qdxhq: null,
      age: null,
      dob: null,
      validations: {
        PatientName: false,
        IdType: false,
        IdCard: false,
        EmpNo: false,
        MobileNumber: false,
        FacilityName: false,
        Remark: true,
        DOB: false,
      },
    };
  }

  validationResponse = (data) => {
    let status = Object.keys(data).findIndex((key, index) => {
      if (
        key !== "data" &&
        key !== "history" &&
        key !== "patient_registration_qvcov"
      ) {
        if (!data[key]) {
          this.props.alert.setSnack({
            open: true,
            msg: "Fill In All Required fields",
            severity: "error",
          });
          return true;
        }
      }
    });
    if (status == -1) return false;
    else return true;
  };

  validateIDCard = (type, number) => {
    let pattern = type === "Passport" ? /^[A-Z]\d{8,8}$/g : /[A-Z0-9]{12,12}$/g;
    let result = pattern.test(number);
    return result;
  };

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onchange_lj4kk = (e) => {
    let result = fieldValidator("name", e?.target?.value);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          PatientName: true,
        },
        patient_registration_lj4kk: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          PatientName: false,
        },
        patient_registration_lj4kk: e?.target?.value,
      });
    }
  };
  onchange_ec1wv = (e) => {
    if (!this.state.patient_registration_eqt7z)
      return this.props.alert.setSnack({
        open: true,
        msg: "Please select the ID Type",
        severity: "error",
      });

    let result = this.validateIDCard(
      this.state.patient_registration_eqt7z.value,
      e?.target?.value
    );

    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          IdCard: true,
        },
        patient_registration_ec1wv: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          IdCard: false,
        },
        patient_registration_ec1wv: e?.target?.value,
      });
    }
  };

  onchange_qlm9w = (e) => {
    let result = fieldValidator("number", e?.target?.value);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          EmpNo: true,
        },
        patient_registration_qlm9w: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          EmpNo: false,
        },
        patient_registration_qlm9w: e?.target?.value,
      });
    }
  };
  onchange_ukwmz = (e) => {
    let result = fieldValidator("mobileNumber", e?.target?.value);
    if (!this.state.patient_registration_ukwmz) {
      e.target.value = "01" + e?.target?.value
    }
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          MobileNumber: true,
        },
        patient_registration_ukwmz: e?.target?.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          MobileNumber: false,
        },
        patient_registration_ukwmz: e?.target?.value,
      });
    }
  };

  onchange_2njp6 = (e) => {
    let result = fieldValidator("stringWithNumber", e?.label);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          FacilityName: true,
        },
        patient_registration_2njp6: e,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          FacilityName: false,
        },
        patient_registration_2njp6: e,
      });
    }
  };

  onchange_qvcov = (e) => {
    let result = e?.target?.value;
    this.setState({
      patient_registration_qvcov: e?.target?.value,
    });
  };

  convertDateToAge = (dob) => {
    var Bday = +new Date(dob);
    return (Date.now() - Bday) / 31557600000;
  };

  onchange_lj4kk2 = (e) => {
    let value = null;
    if (e.target.value) value = this.convertDateToAge(e.target.value);
    if (value) {
      this.setState({
        validations: {
          ...this.state.validations,
          DOB: true,
        },
        age: parseInt(value),
        dob: e.target.value,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          DOB: false,
        },
        age: parseInt(value),
        dob: e.target.value,
      });
    }
  };

  onchange_eqt7z = (e) => {
    let result = fieldValidator("stringWithNumber", e?.value);
    if (result) {
      this.setState({
        validations: {
          ...this.state.validations,
          IdType: true,
        },
        patient_registration_eqt7z: e,
      });
    } else {
      this.setState({
        validations: {
          ...this.state.validations,
          IdType: false,
        },
        patient_registration_eqt7z: e,
      });
    }
  };

  onclick_qdxhq = async (event, data) => {
    const payload0 = {
      patient_registration_eqt7z: this.state.patient_registration_eqt7z?.label,

      patient_registration_ec1wv: this.state.patient_registration_ec1wv,

      patient_registration_ukwmz: this.state.patient_registration_ukwmz,

      patient_registration_lj4kk: this.state.patient_registration_lj4kk,

      patient_registration_qlm9w: this.state.patient_registration_qlm9w,

      patient_registration_2njp6: this.state.patient_registration_2njp6?.value,

      patient_registration_qvcov: this.state.patient_registration_qvcov,

      age: this.state.age,

      dob: this.state.dob,

      history: this.props.history.location.state,
      data: data,
    };

    if (this.validationResponse(payload0)) {
      return true;
    }

    if (!Object.values(this.state.validations).every((val) => val === true)) {
      let result = Object.entries(this.state.validations).reduce(
        (allVal, val) => {
          if (val[1] === false) {
            return [val[0], ...allVal];
          } else {
            return [...allVal];
          }
        },
        []
      );
      return this.props.alert.setSnack({
        open: true,
        msg: `Input fields-${result.join(", ")} are not in the required format`,
        severity: "error",
      });
    }

    let data0 = await this.props.Patient_RegistrationPatient_Insert_Query_QdXHq(
      payload0
    );
    if (data0?.payload?.Code === 200) {
      this.setState({
        patient_registration_qdxhq_9sv6l: true,
        SuccessState: data0?.payload?.Result[0]?.properties?.doc,
      });
    }
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  async componentDidMount() {
    /// sample localstorage translation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    this.props.FacilityName({ keyName: "Facility_Name", valueName: "_id" });

    ///casbin implementation
    // const permission = await checkWithCasbin(["patient_registration"])
  }

  render() {
    console.log(this.state);
    const { facilityNameOptions } = this.props;
    // let pattern = /^[a-z0-9]{MIN_CHARS,MAX_CHARS}$/i;
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <div key={"0"}>
              <input
                key={"0"}
                type={"hidden"}
                id={"anPageName"}
                value={"patient-registration"}
              ></input>
              <div key={"1"} class={"patient-registration screen"}>
                <div
                  key={"0"}
                  class={"group-93722-zVTIDx"}
                  style={{ display: "grid", gridGap: "64px" }}
                >
                  <div
                    key={"0"}
                    class={"rectangle-47148-txmB1b"}
                    style={{ height: "620px" }}
                  ></div>
                  {/* patient name */}
                  <div key={"1"} class={"qdm-textinput-txmB1b"}>
                    <TextInput
                      key={"0"}
                      onChange={(e) => this.onchange_lj4kk(e)}
                      onBlur={(e) => {}}
                      type={"text"}
                      name={t("text")}
                      variant={"outlined"}
                      helperTextType={"error"}
                      label={t("Patient Name")}
                      labelStyle={{
                        fontSize: "12px",
                        marginBottom: "3px",
                        color: "gray",
                        marginTop: "0px",
                      }}
                      autoFocus={false}
                      autoComplete={"off"}
                      search={false}
                      borderColor={"#cbd5e1"}
                      elevation={"0"}
                      placeholder={""}
                      size={"small"}
                      isRequired={true}
                      hoverColor={"#000"}
                      dateIcon={""}
                      maxLength={"100"}
                      iconText={""}
                      minLength={"100"}
                      style={qdmstyles.LJKK}
                    ></TextInput>
                  </div>
                  {/* remark */}
                  <div key={"2"} class={"qdm-textinput-mReW0w"}>
                    <TextInput
                      key={"0"}
                      onChange={(e) => this.onchange_qvcov(e)}
                      type={"text"}
                      name={t("text")}
                      variant={"outlined"}
                      helperTextType={"error"}
                      label={t("Remark")}
                      labelStyle={{
                        fontSize: "12px",
                        marginBottom: "3px",
                        color: "gray",
                      }}
                      autoFocus={false}
                      autoComplete={"off"}
                      search={false}
                      borderColor={"#cbd5e1"}
                      elevation={"0"}
                      placeholder={""}
                      size={"small"}
                      hoverColor={"#000"}
                      dateIcon={""}
                      maxLength={"100"}
                      iconText={""}
                      minLength={"100"}
                    ></TextInput>
                  </div>
                  {/* facility name */}
                  <div key={"3"} class={"qdm-dropdown-txmB1b"}>
                    {facilityNameOptions && (
                      <Dropdown
                        key={"0"}
                        onChange={(e) => this.onchange_2njp6(e)}
                        elevation={"1"}
                        fullWidth={true}
                        labelStyle={{
                          fontSize: "12px",
                          marginBottom: "3px",
                          color: "gray",
                          marginTop: "0px",
                        }}
                        dropDownTextSize={"14"}
                        dropDownTextColor={"#000"}
                        activeTextColor={"#000"}
                        dropdownListMargin={"0"}
                        dropdownListPadding={"8"}
                        options={facilityNameOptions}
                        label={t("Facility Name")}
                        isRequired={true}
                        Url={""}
                        keyName={""}
                        valueName={""}
                        inLineStyles={qdmstyles.BOiTD}
                        placeholder={"Search Location"}
                      ></Dropdown>
                    )}
                  </div>
                  {/* emp no */}
                  <div key={"4"} class={"qdm-textinput-YOgISv"}>
                    <TextInput
                      key={"0"}
                      onChange={(e) => this.onchange_qlm9w(e)}
                      type={"text"}
                      name={t("text")}
                      variant={"outlined"}
                      helperTextType={"error"}
                      label={t("EMP No")}
                      labelStyle={{
                        fontSize: "12px",
                        marginBottom: "3px",
                        color: "gray",
                      }}
                      autoFocus={false}
                      autoComplete={"off"}
                      search={false}
                      borderColor={"#cbd5e1"}
                      elevation={"0"}
                      placeholder={""}
                      size={"small"}
                      isRequired={true}
                      hoverColor={"#000"}
                      dateIcon={""}
                      maxLength={"100"}
                      iconText={""}
                      minLength={"100"}
                    ></TextInput>
                  </div>
                  {/* mobile no */}
                  <div key={"5"} class={"qdm-textinput-ILayjh"}>
                    <TextInput
                      key={"0"}
                      onChange={(e) => this.onchange_ukwmz(e)}
                      type={"text"}
                      name={t("text")}
                      variant={"outlined"}
                      helperTextType={"error"}
                      label={t("Mobile Number")}
                      onKeyDown={(e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault()
                          return
                        }
                      }}
                      labelStyle={{
                        fontSize: "12px",
                        marginBottom: "3px",
                        color: "gray",
                      }}
                      autoFocus={false}
                      autoComplete={"off"}
                      search={false}
                      borderColor={"#cbd5e1"}
                      elevation={"0"}
                      placeholder={""}
                      size={"small"}
                      isRequired={true}
                      hoverColor={"#000"}
                      dateIcon={""}
                      maxLength={10}
                      iconText={""}
                      minLength={10}
                    ></TextInput>
                  </div>
                  {/* id details */}
                  <div key={"6"} class={"component-93700-txmB1b"}>
                    <div key={"0"} class={"qdm-textinput-5VfWu1"}>
                      <TextInput
                        key={"0"}
                        onChange={(e) => this.onchange_ec1wv(e)}
                        type={"text"}
                        name={t("text")}
                        variant={"outlined"}
                        helperTextType={"error"}
                        label={t("ID Card")}
                        labelStyle={{
                          fontSize: "12px",
                          marginBottom: "3px",
                          color: "gray",
                        }}
                        autoFocus={false}
                        autoComplete={"off"}
                        search={false}
                        borderColor={"#cbd5e1"}
                        elevation={"0"}
                        placeholder={""}
                        size={"small"}
                        isRequired={true}
                        hoverColor={"#000"}
                        dateIcon={""}
                        maxLength={
                          this.state.patient_registration_eqt7z?.value ===
                          "Passport"
                            ? "9"
                            : "12"
                        }
                        iconText={""}
                        minLength={"100"}
                      ></TextInput>
                    </div>
                    <div
                      style={{ marginBottom: "3px", top: "-1px" }}
                      key={"1"}
                      class={"qdm-dropdown-5VfWu1"}
                    >
                      <Dropdown
                        key={"0"}
                        onChange={(e) => this.onchange_eqt7z(e)}
                        elevation={"1"}
                        fullWidth={true}
                        dropDownTextSize={"14"}
                        dropDownTextColor={"#000"}
                        labelStyle={{
                          fontSize: "12px",
                          marginBottom: "3px",
                          color: "gray",
                          marginTop: "0px",
                        }}
                        activeTextColor={"#000"}
                        dropdownListMargin={"0"}
                        dropdownListPadding={"8"}
                        options={[
                          { value: "Passport", label: "Passport" },
                          { value: "NRIC", label: "NRIC" },
                        ]}
                        label={t("ID Type")}
                        isRequired={false}
                        Url={""}
                        keyName={""}
                        valueName={""}
                        inLineStyles={qdmstyles.EqTZ}
                        placeholder={"Select Id"}
                      ></Dropdown>
                    </div>
                  </div>

                  {/* dob start */}
                  <div key={"6"} class={"component-93700-txmKli"}>
                    <div key={"0"} class={"qdm-dropdown-5VfWu2"}>
                      <p className={"sc-gsDKAQ gHKREI"}>
                        Date of Birth{" "}
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </p>
                      <input
                        type={"date"}
                        style={{ height: "32px", width: "70%" }}
                        onChange={this.onchange_lj4kk2}
                        required
                      />
                    </div>
                    <div
                      style={{ marginBottom: "3px", top: "-1px" }}
                      key={"1"}
                      class={"qdm-textinput-5VfWu2"}
                    >
                      <TextInput
                        key={"0"}
                        onBlur={(e) => {}}
                        type={"text"}
                        name={t("text")}
                        variant={"outlined"}
                        helperTextType={"error"}
                        label={t("age")}
                        labelStyle={{
                          fontSize: "12px",
                          marginBottom: "3px",
                          color: "gray",
                          marginTop: "0px",
                        }}
                        autoFocus={false}
                        autoComplete={"off"}
                        search={false}
                        borderColor={"#cbd5e1"}
                        elevation={"0"}
                        placeholder={""}
                        size={"small"}
                        hoverColor={"#000"}
                        dateIcon={""}
                        maxLength={"100"}
                        iconText={""}
                        minLength={"100"}
                        style={{ width: "100%" }}
                        disabled={true}
                        isRequired={true}
                        value={this.state?.age}
                      ></TextInput>
                    </div>
                  </div>
                  {/* dob end */}
                  <div key={"7"} class={"qdm-button-txmB1b"}>
                    <div
                      key={"0"}
                      onClick={(e) => this.onclick_qdxhq(e)}
                      class={"submit-J1HMpe"}
                    >
                      Submit
                    </div>
                    <Modal
                      open={Boolean(
                        this.state?.patient_registration_qdxhq_9sv6l === true
                      )}
                      onClose={() => {
                        this.setState({
                          patient_registration_qdxhq_9sv6l: null,
                        });
                        window.location.reload();
                      }}
                    >
                      <Success state={this.state.SuccessState} />
                    </Modal>
                  </div>
                  {/* <div
                    key={"8"}
                    class={"cancel-button-txmB1b"}
                    style={{ bottom: "86px", right: "calc(37.38% - 41px)" }}
                  >
                    <div key={"0"} class={"rectangle-47166-jELxVt"}></div>
                    <div key={"1"} class={"cancel-jELxVt"}>
                      Cancel
                    </div>
                  </div> */}
                  <div key={"9"} class={"patient-registration-txmB1b"}>
                    Patient Registration
                  </div>
                </div>
                <img
                  key={"1"}
                  class={"path-93228-zVTIDx"}
                  src={
                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744560"
                  }
                  alt={"path-93228@1x.png"}
                ></img>
                <div key={"2"} class={"group-93721-zVTIDx"}>
                  <div key={"0"} class={"rectangle-47180-C1tNJq"}></div>
                  <div key={"1"} class={"login-avatar-C1tNJq"}>
                    <Logout />
                  </div>
                  <div key={"2"} class={"patient-registration-C1tNJq"}>
                    Patient Registration
                  </div>
                </div>
                <div key={"3"} class={"group-93720-zVTIDx"}>
                  <div key={"0"} class={"rectangle-47173-LirMrN"}></div>
                  <div key={"1"} class={"group-93719-LirMrN"}>
                    <div key={"0"} class={"logo-oSnKxZ"}>
                      <img
                        key={"0"}
                        class={"x51-client_profile_subscription-10-wYxAZU"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183739820"
                        }
                        alt={"5-1-client-profile-subscription---10@1x.png"}
                      ></img>
                      <div
                        key={"1"}
                        onClick={() => {
                          this.props.history.push("/login");
                        }}
                        class={"global-doctors-wYxAZU"}
                      >
                        <span key={"0"} class={"span0-2KEHE5"}>
                          Global
                        </span>
                        <span key={"1"} class={"span1-2KEHE5"}>
                          Doctors
                        </span>
                      </div>
                    </div>
                    <div key={"1"} class={"rectangle-47179-oSnKxZ"}></div>
                    <div key={"2"} class={"version-oSnKxZ"}>
                      <div key={"0"} class={"v1001-hO8D8V"}>
                        {"v" + localStorage?.version ?? ""}
                      </div>
                    </div>
                    <div key={"3"} class={"login-details-oSnKxZ"}>
                      <div key={"0"} class={"hello-IgMki6"}>
                        Hello{" "}
                      </div>
                      <div key={"1"} class={"mr-rakin-IgMki6"}>
                        {sessionStorage.getItem("name")}
                      </div>
                      <div key={"2"} class={"sathishrakinainqacom-IgMki6"}>
                        {sessionStorage.getItem("username")}
                      </div>
                      <img
                        key={"3"}
                        class={"line-9-IgMki6"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744502"
                        }
                        alt={"line-9@1x.png"}
                      ></img>
                    </div>
                    <div
                      key={"4"}
                      class={"reports-oSnKxZ"}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/reports");
                        }}
                        class={"reports-74itnq roboto-medium-dove-gray-14px"}
                      >
                        Reports
                      </div>
                      <img
                        key={"1"}
                        class={"download-7-74itnq"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744506"
                        }
                        alt={"download--7-@1x.png"}
                      ></img>
                    </div>
                    <div key={"5"} class={"patient-regn-oSnKxZ"}>
                      <div key={"0"} class={"patient-registration-NmGpcR"}>
                        Patient Registration
                      </div>
                      <img
                        key={"1"}
                        class={"rectangle-47175-NmGpcR"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744525"
                        }
                        alt={"rectangle-47175@1x.png"}
                      ></img>
                    </div>
                    <div
                      key={"6"}
                      class={"master-oSnKxZ"}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/specialist_doctor");
                        }}
                        class={"master-fgeYR1 roboto-medium-dove-gray-14px"}
                      >
                        Master
                      </div>
                      <img
                        key={"1"}
                        class={"material-master-data-fgeYR1"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744545"
                        }
                        alt={
                          "material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"
                        }
                      ></img>
                    </div>
                    <div
                      key={"7"}
                      class={"visit-regn-oSnKxZ"}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        key={"0"}
                        onClick={() => {
                          this.props.history.push("/visit_registration");
                        }}
                        class={
                          "visit-registration-PhuJQ4 roboto-medium-dove-gray-14px"
                        }
                      >
                        Visit Registration
                      </div>
                      <img
                        key={"1"}
                        class={"download-6-PhuJQ4"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/183744562"
                        }
                        alt={"download--6-@1x.png"}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    facilityNameOptions:
      state.All_Dropdowns_Reducer?.facilityName?.data?.result,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(withRouter(translate()(PatientRegistration))));
